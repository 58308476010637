import React, { lazy, useEffect } from 'react';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import ColoredBar, { ColoredBarColor } from '../ColoredBar';
import Content from '../Content';
import { scrollToHashLink } from '../ScrollUtils';
import { SuspendedRoute } from '../../utils/SuspendedRoutes';
import Navigation from './Navigation';
import styled from 'styled-components';
import { MQ } from '../../style/MediaQueryHelpers';
import { GeoLocEnum, LegalLinkPaths } from './Links';

const SoftwareAgreementPage = lazy(() => import('./agreement/SoftwareAgreementPage'));
const Schedule1 = lazy(() => import('./agreement/Schedule1'));
const Schedule2 = lazy(() => import('./agreement/Schedule2'));
const Schedule3 = lazy(() => import('./agreement/Schedule3'));

const PrivacyPolicyPage = lazy(() => import('./policies/PrivacyPolicyPage'));
const DataRetentionPolicyPage = lazy(() => import('./policies/DataRetentionPolicyPage'));
const DataDeletionPolicyPage = lazy(() => import('./policies/DataDeletionPolicyPage'));
const DataStoragePolicyPage = lazy(() => import('./policies/DataStoragePolicyPage'));
const SubprocessorsPage = lazy(() => import('./policies/SubprocessorsPage'));

const ReportVulnerabilityPage = lazy(() => import('./security/ReportVulnerabilityPage'));

type TermsPagesProps = {};

const TermsPages: React.FC<TermsPagesProps & RouteComponentProps> = (props) => {
	useEffect(() => {
		scrollToHashLink(props.location.hash);
	}, [props.location.hash]);

	return (
		<Switch>
			<Route exact path={LegalLinkPaths.TERMS_RAW}>
				<Redirect to={LegalLinkPaths.TERMS_LICENCE_AGREEMENT(GeoLocEnum.us)} />
			</Route>
			<Route exact path={LegalLinkPaths.TERMS_ROUTE(GeoLocEnum.us)}>
				<Redirect to={LegalLinkPaths.TERMS_LICENCE_AGREEMENT(GeoLocEnum.us)} />
			</Route>
			<Route exact path={LegalLinkPaths.TERMS_ROUTE(GeoLocEnum.gb)}>
				<Redirect to={LegalLinkPaths.TERMS_LICENCE_AGREEMENT(GeoLocEnum.gb)} />
			</Route>

			{/* AGREEMENT */}
			<Route path={LegalLinkPaths.TERMS_LICENCE_AGREEMENT_ROUTE}>
				<TermsWrapper title="General Terms">
					{SuspendedRoute(LegalLinkPaths.TERMS_PRIVACY_POLICY_ROUTE, <SoftwareAgreementPage />)}
				</TermsWrapper>
			</Route>
			<Route path={LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_1_ROUTE}>
				<TermsWrapper title="Schedule 1 - Service Description">
					{SuspendedRoute(LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_1_ROUTE, <Schedule1 />)}
				</TermsWrapper>
			</Route>
			<Route path={LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_2_ROUTE}>
				<TermsWrapper title="Schedule 2 - Acceptable Use Policy">
					{SuspendedRoute(LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_2_ROUTE, <Schedule2 />)}
				</TermsWrapper>
			</Route>
			<Route path={LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_3_ROUTE}>
				<TermsWrapper title="Schedule 3 - Data Processing Schedule">
					{SuspendedRoute(LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_3_ROUTE, <Schedule3 />)}
				</TermsWrapper>
			</Route>

			{/* POLICIES */}
			<Route path={LegalLinkPaths.TERMS_PRIVACY_POLICY_ROUTE}>
				<TermsWrapper title="Privacy Policy">
					{SuspendedRoute(LegalLinkPaths.TERMS_PRIVACY_POLICY_ROUTE, <PrivacyPolicyPage />)}
				</TermsWrapper>
			</Route>
			<Route path={LegalLinkPaths.TERMS_DATA_RETENTION_POLICY_ROUTE}>
				<TermsWrapper title="Data Retention Policy">
					{SuspendedRoute(LegalLinkPaths.TERMS_PRIVACY_POLICY_ROUTE, <DataRetentionPolicyPage />)}
				</TermsWrapper>
			</Route>
			<Route path={LegalLinkPaths.TERMS_DATA_DELETION_POLICY_ROUTE}>
				<TermsWrapper title="Data Deletion Policy">
					{SuspendedRoute(LegalLinkPaths.TERMS_PRIVACY_POLICY_ROUTE, <DataDeletionPolicyPage />)}
				</TermsWrapper>
			</Route>
			<Route path={LegalLinkPaths.TERMS_DATA_STORAGE_POLICY_ROUTE}>
				<TermsWrapper title="Data Storage Policy">
					{SuspendedRoute(LegalLinkPaths.TERMS_PRIVACY_POLICY_ROUTE, <DataStoragePolicyPage />)}
				</TermsWrapper>
			</Route>
			<Route path={LegalLinkPaths.TERMS_SUBPROCESSORS_ROUTE}>
				<TermsWrapper title="Akazio Subprocessors">
					{SuspendedRoute(LegalLinkPaths.TERMS_SUBPROCESSORS_ROUTE, <SubprocessorsPage />)}
				</TermsWrapper>
			</Route>

			{/* SECURITY */}
			<Route path={LegalLinkPaths.TERMS_SECURITY_REPORT_VULNERABILITY_ROUTE}>
				<TermsWrapper title="Report a Vulnerability">
					{SuspendedRoute(
						LegalLinkPaths.TERMS_SECURITY_REPORT_VULNERABILITY_ROUTE,
						<ReportVulnerabilityPage />
					)}
				</TermsWrapper>
			</Route>
		</Switch>
	);
};

const BigTitle = styled.h1`
	background: #f5f5f5;
	text-align: center;
	font-size: 55px;
	padding: 100px;
	margin-top: 40px;
	font-weight: bold;

	${MQ.W640} {
		padding: 30px;
		margin-top: 10px;
		font-size: 24px;
	}
`;

const PageWrapper = styled.div`
	display: flex;
	flex-direction: row;

	${MQ.W640} {
		flex-direction: column;
	}
`;

const NavigationWrapper = styled.div`
	width: 30%;

	${MQ.W640} {
		width: 100%;
	}
`;

const ProseWrapper = styled.div`
	width: 70%;

	${MQ.W640} {
		width: 100%;
	}
`;

type TermsWrapperProps = {
	title: string;
};

const TermsWrapper: React.FC<TermsWrapperProps> = (props) => {
	return (
		<>
			<BigTitle>{props.title}</BigTitle>
			<ColoredBar
				compact
				title={''}
				color={ColoredBarColor.None}
				element={
					<Content>
						<PageWrapper>
							<NavigationWrapper>
								<Navigation />
							</NavigationWrapper>
							<ProseWrapper>{props.children}</ProseWrapper>
							{/* <p>Last updated: July 17, 2020</p> */}
						</PageWrapper>
					</Content>
				}
			/>
		</>
	);
};

export default withRouter(TermsPages);
