import React from 'react';
import { ENABLE_INSTANT_BBG_PAGE, ENABLE_SIGNAL_PAGE, ENABLE_WHATSAPP_PAGE } from '../../../AkazioConfig';
import { LinkPaths } from '../../Constants';
import {
	ListPricingUserEmail,
	ListPricingUserRingCentral,
	ListPricingUserSlackOrTeams,
} from '../../pricing/data/LitePricingConstants';
import { ConnectorLinkToTypeEnum } from './ConnectorLinkToTypePages';
import { ConnectorSnippetEnum } from './ConnectorSnippets';
import RingCentralPartnerElement, { RC_APP_GALLERY_LINK } from './custom-connector-parts/RingCentralPartnerElement';
import { BlurbWrapper, HeroPara } from './Styles';

export enum ConnectorTypeEnum {
	Teams = 'Teams',
	Office356 = 'Office365',
	MsftExchange = 'MsftExchange',
	GMail = 'GMail',
	Slack = 'Slack',
	SalesForceEmail = 'SalesForceEmail',
	RedTailEmail = 'RedTailEmail',
	RingCentral = 'RingCentral',
	Telus = 'Telus',
	AttOfficeAtHand = 'AttOfficeAtHand',
	AvayaCloudOffice = 'AvayaCloudOffice',
	BtCloudWork = 'BtCloudWork',
	AtosUnifyOffice = 'AtosUnifyOffice',

	WhatsApp = 'WhatsApp',
	Signal = 'Signal',
	InstantBloomberg = 'InstantBloomberg',
}

export type ConnectorDictionaryItem = {
	type: ConnectorTypeEnum;
	link: string;
	name: string;
	shortName?: string;
	icon: (size: number) => string;
	blurb: JSX.Element;
	subheader?: JSX.Element;
	connectorFunctionalityItems: ConnectorFunctionalityItem[];
	connectorSnippets: ConnectorSnippetEnum[];

	title: string;
	description: string;
	screenshots?: ConnectorDictionaryItemScreenshot[];
	isBeta?: boolean;

	liteAdvert?: boolean;
	liteUserPrice?: number;

	partnerElement?: JSX.Element;
	partnerLink?: string;

	connectorLinkToTypes: ConnectorLinkToTypeEnum[];
};

export type ConnectorDictionaryItemScreenshot = {
	mobile: string;
	thumbnail: string;
	src: string;
	alt: string;
};

export type ConnectorFunctionalityItem = {
	title: string;
	text: string;
};

const createScreenshot = (connectorFolder: string, fileNameRoot: string, alt: string) => {
	const root = `/images/screenshots/connectors/${connectorFolder}/${fileNameRoot}`;
	return {
		mobile: `${root}_mob.png`,
		thumbnail: `${root}_thumb.png`,
		src: `${root}.png`,
		alt,
	};
};

const getTitle = (name: string) => `Akazio | Capture, archive and monitor all ${name} data`;
const getDescription = (name: string) =>
	`How Akazio can capture ${name} communication data, index it, and provide a rich compliance and eDiscovery portal for business customers`;

const getRingCentralVariation = (
	connectorType: ConnectorTypeEnum,
	link: string,
	name: string,
	shortName: string,
	icon: (w: number) => string,
	mockImage: string | undefined,
	location: string,
	indefiniteArticle: 'a' | 'an'
) => ({
	type: connectorType,
	link: link,
	name: name,
	shortName: shortName,
	icon: (w: number) => `/icons/${icon(w)}`,
	title: getTitle(shortName),
	description: getDescription(shortName),
	connectorLinkToTypes: [ConnectorLinkToTypeEnum.InstantMessage, ConnectorLinkToTypeEnum.AudioVideo],
	subheader: (
		<HeroPara>
			Akazio can work hand-in-hand with {indefiniteArticle} {shortName} subscription, to journal and securely
			store phone, message and fax data in an integrated cloud archive.
		</HeroPara>
	),
	blurb: (
		<BlurbWrapper>
			<p>
				{`${shortName}, primarily offered in ${location}, offers an integrated suite of telephony and messaging tools that businesses can use to organise both their internal and external communications. Integrations with other messaging platforms, chatbot functionality, and a video and chat offering means that ${shortName} data can touch every part of an organization.`}
			</p>
			{mockImage && (
				<div
					style={{ marginTop: 60, marginBottom: 60, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
				>
					<img src={`/images/${mockImage}`} alt={`Akazio and ${name} mock`} width={'100%'} />
				</div>
			)}
			<p>
				{`Using Akazio, communications can be searched, played and reviewed from a single location, which drastically reduces the time and effort that might otherwise be spent manually locating, downloading and viewing the data.`}
			</p>
		</BlurbWrapper>
	),
	connectorFunctionalityItems: [
		{
			title: 'Channels',
			text: 'Akazio captures all phone call logs (with recording if available), voicemail logs (with recordings and transcripts if available), SMS conversations (with attached files and images), and faxes.',
		},
		{
			title: 'Search Phone Calls',
			text: 'Search on the duration, direction and result (answered, rejected, failed etc) of calls. Then listen to them without leaving the Akazio web portal.',
		},
		{
			title: 'Combined View',
			text: `Search and review all the different ${shortName} communication channels, plus other email or instant message channels (like Slack or Microsoft Teams) in a single view, and follow conversations as they move from one channel to another.`,
		},
	],
	connectorSnippets: [ConnectorSnippetEnum.CaptureApp],
	liteAdvert: true,
	liteUserPrice: ListPricingUserRingCentral,
});

const ConnectorListInternal: ConnectorDictionaryItem[] = [
	{
		type: ConnectorTypeEnum.Teams,
		link: LinkPaths.CONNECTOR_MSFT_TEAMS,
		name: 'Microsoft Teams',
		shortName: 'Teams',
		icon: (s) => `/icons/teams_${s}.png`,
		title: getTitle('Microsoft Teams'),
		description: getDescription('Microsoft Teams'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.InstantMessage],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture Microsoft Teams communication data across your organization, and store
				it securely in an integrated cloud platform, where it can then be searched, automatically tagged, and
				interrogated by your legal and compliance users.
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Microsoft Teams has grown significantly in popularity in recent years. It integrates with the wider
					Microsoft Office 365 suite of tools, allowing users to seamlessly communicate via group or
					individual chats, collaborate against shared documents, and engage in video calls with other
					members.
				</p>
				<div
					style={{ marginTop: 60, marginBottom: 60, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
				>
					<img src={'/images/akazio-teams-mock.webp'} alt={'Akazio and Teams mock'} width={'100%'} />
				</div>
				<p>
					Whether you are a financial institution forced to adhere to regulations (such as MiFID II or the
					FINRA Rules on Digital Communications) or simply a company that needs to archive its communication
					data for HR or GDPR reasons, Microsoft Teams contains a trove of complex information that you will
					want to capture and retain, while still maintaining the context and metadata associated with
					messages, files and calls.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Channels',
				text: 'Capture public & private group chats, and one-to-one chats from all internal and external users. Any attachments, image, emojis or GIFs are also captured, archived and indexed for later review by platform users.',
			},
			{
				title: 'Format Preservation',
				text: 'Maintain the format of the data as it was capture in Teams - Akazio never flattens information to email form, and keeps the context of the data (enabling reviewers to more effectively follow conversation threads).',
			},
			{
				title: 'No Behavior Change',
				text: 'We require no change to the way your users interact with Teams - capture and monitoring can be configured transparently by an administrator using the step-by-step guide from our integrated help pages.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureApp],
		liteAdvert: true,
		liteUserPrice: ListPricingUserSlackOrTeams,
	},
	{
		type: ConnectorTypeEnum.Office356,
		link: LinkPaths.CONNECTOR_OFFICE_365,
		name: 'Office 365 Exchange Online',
		shortName: 'O365',
		icon: (s) => `/icons/o365_${s}.png`,
		title: getTitle('Office 365 email'),
		description: getDescription('Office 365 Exchange Online'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.Email],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all Office 365 emails (internal, external, BCC and
				distribution groups), as well as other Microsoft and non-Microsoft IM channels (e.g. Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Office 365 is used by countless businesses the world over to manage email, and is often where a
					majority of internal and external communication takes place for any one business. This makes it a
					paramount concern for the organization to ensure that such communications are properly captured,
					archived and monitored.
				</p>
				<p>
					Akazio allows your business to adhere to the ever-evolving list of regulations institutions are now
					facing (MiFID II / FINRA / HIPAA). All files are persisted in tamper-proof storage in a secure cloud
					platform accessible from a modern web portal.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Full Message Context',
				text: 'Akazio maintains all message context and metadata from the files it stores, exposing all of it to a powerful search function that allows users to interrogate TBs of data for keywords and trends in seconds.',
			},
			{
				title: 'Secure Archive with Compliance Workflow',
				text: 'Your data is protected by strong encryption (both at-rest and in-transit), WORM storage, and modern cloud data access protection. And all this while offering an integrated compliance and legal workflow that lets users search, interrogate and export data from one convenient platform.',
			},
			{
				title: 'Multiple Channel Types',
				text: 'Unlike other providers, Akazio captures and stores other mediums (like instant messaging & social media) in the same dynamic indexable model, so searches can be performed across multiple different communication types with ease.',
			},
			{
				title: 'SSO / Active Directory Sync',
				text: 'The platform integrates seamlessly with Azure Online, and can keep the platform users synchronized with your golden source, to facilitate SSO and an always-up-to-date user register.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureJournaling],
		liteAdvert: true,
		liteUserPrice: ListPricingUserEmail,
	},
	{
		type: ConnectorTypeEnum.MsftExchange,
		link: LinkPaths.CONNECTOR_MSFT_EXCHANGE,
		name: 'Microsoft Exchange',
		shortName: 'Exchange',
		icon: (s) => `/icons/ms-xchange_${s}.png`,
		title: getTitle('Microsoft Exchange'),
		description: getDescription('Microsoft Exchange'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.Email],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all Microsoft Exchange emails (internal, external, BCC and
				distribution groups), as well as other Microsoft and non-Microsoft IM channels (e.g. Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Many organizations prefer to use Microsoft Exchange Server to host their email infrastructure due to
					the piece-of-mind an established system like this offers, especially when it comes to security
					concerns. To complement the functionality already offered by Exchange, Akazio can aid in capturing
					and monitoring email data.
				</p>
				<p>
					Akazio helps your business adhere to the ever-growing list of regulations institutions now face
					(MiFID II / FINRA / HIPAA). All files are persisted in tamper-proof storage in a secure cloud
					platform accessible from a tidy web portal.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Full Message Context',
				text: 'Akazio stores all email context and metadata from the files it stores, exposing all of it to a powerful search, allowing users to sift through millions of emails in a greatly reduced timeframe.',
			},
			{
				title: 'Multiple Channel Types',
				text: 'Unlike some other platforms, Akazio captures and stores other mediums (like instant messaging & social media) in the same indexable model, so searches can be performed across multiple different communication types with ease.',
			},
			{
				title: 'Secure Archive',
				text: 'Our number one concern is maintaining the security and integrity of the data stored in the archive. You can be confident that your files are protected with strong encryption (both at-rest and in-transit), and access to the archive is locked-down with SSO, IP safelisting and regular penetration tests.',
			},
			{
				title: 'Compliance and Legal Workflows',
				text: 'Once uploaded to the archive, messages can be automatically tagged by compliance policies to aid in the later manual review performed by users (in the same screens where they can also manage legal cases and export pertinent files).',
			},
			{
				title: 'SSO / Active Directory Sync',
				text: 'The archive can be integrated with Active Directory to keep the platform users synchronized with your golden source, and to facilitate SSO and an always-up-to-date user register.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureJournaling],
		liteAdvert: true,
		liteUserPrice: ListPricingUserEmail,
	},
	{
		type: ConnectorTypeEnum.Slack,
		link: LinkPaths.CONNECTOR_SLACK,
		name: 'Slack',
		icon: (s) => `/icons/slack_${s}.png`,
		title: getTitle('Slack'),
		description: getDescription('Slack'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.InstantMessage],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture all Slack communication data generated by your organization, and store
				it securely in an integrated cloud platform, where it can then be searched, automatically tagged, and
				interrogated by your legal and compliance users.
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Slack is a channel-based messaging platform in which users can collaborate with messages, calls,
					file sharing, and by utilizing apps to further integrate with other platforms. Slack can be used for
					both internal and external communication, and is a popular platform when dealing with customers
					directly.
				</p>
				<div
					style={{ marginTop: 60, marginBottom: 60, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
				>
					<img src={'/images/akazio-slack-mock.webp'} alt={'Akazio and Slack mock'} width={'100%'} />
				</div>
				<p>
					WFH initiatives and remote collaboration have made messaging service such as Slack even more
					relevant, and being able to capture the communication shared and archive them in such as way as to
					be compliant with the various financial regulations (eg MiFID II / the FINRA Rules on Digital
					Communications), or else simply to protect your company against GDPR requests or HR complaints.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Channels',
				text: 'Akazio captures all private, public and one-to-one messages sent via Slack, as well as all images, emojis, threads and files shared.',
			},
			{
				title: 'All Subscription Types',
				text: "It doesn't matter which Slack package your are subscribed to - Akazio can capture and archive messages regardless.",
			},
			{
				title: 'Format Preservation',
				text: 'Maintain the format of the data as it was capture in Slack - Akazio never flattens information to email form, and keeps the context of the data (enabling reviewers to more effectively follow conversation threads).',
			},
			{
				title: 'Legal & eDiscovery',
				text: 'All files and metadata are persisted in their original format, so exporting any pertinent data for legal or eDiscovery reasons can easily be achieved using the integrated case management functions of the platform.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureApp],
		screenshots: [
			createScreenshot(
				'slack',
				'akazio-slack-conversation-1',
				'Slack channel conversation in Akazio Cloud Archive'
			),
			createScreenshot(
				'slack',
				'akazio-slack-conversation-2',
				'Slack channel conversation in Akazio Cloud Archive'
			),
			createScreenshot(
				'slack',
				'akazio-slack-setup',
				'Slack connection setup demonstration Akazio Cloud Archive'
			),
		],
		liteAdvert: true,
		liteUserPrice: ListPricingUserSlackOrTeams,
	},
	{
		type: ConnectorTypeEnum.RingCentral,
		link: LinkPaths.CONNECTOR_RINGCENTRAL,
		name: 'RingCentral',
		icon: (s) => `/icons/ringcentral_${s}.png`,
		title: getTitle('RingCentral'),
		description: getDescription('RingCentral'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.InstantMessage, ConnectorLinkToTypeEnum.AudioVideo],
		subheader: (
			<HeroPara>
				Akazio can work hand-in-hand with a RingCentral subscription, to journal and securely store phone,
				message and fax data in an integrated cloud archive.{' '}
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					RingCentral offers an integrated suite of telephony and messaging tools that businesses can use to
					organise both their internal and external communications. Integrations with other messaging
					platforms, chatbot functionality, and a video and chat offering to rival Zoom means that RingCentral
					data can touch every part of an organization.
				</p>
				<div
					style={{ marginTop: 60, marginBottom: 60, maxWidth: 500, marginLeft: 'auto', marginRight: 'auto' }}
				>
					<img src={'/images/akazio-rc-mock.webp'} alt={'Akazio and RingCentral mock'} width={'100%'} />
				</div>
				<p>
					Using Akazio, communications can be searched, played and reviewed from a single location, which
					drastically reduces the time and effort that might otherwise be spent manually locating, downloading
					and viewing the data.
				</p>
				<p>
					RingCentral storage in Akazio can take advantage of the in-built transcription services, allowing
					users to search the content of phone calls and video meetings.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Channels',
				text: 'Akazio captures all phone call logs (with recording if available), voicemail logs (with recordings and transcripts if available), SMS conversations (with attached files and images), and faxes.',
			},
			{
				title: 'Search Phone Calls',
				text: 'Search on the duration, direction and result (answered, rejected, failed etc) of calls. Then listen to them without leaving the Akazio web portal.',
			},
			{
				title: 'Combined View',
				text: 'Search and review all the different RingCentral communication channels, plus other email or instant message channels (like Slack or Microsoft Teams) in a single view, and follow conversations as they move from one channel to another.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureApp],
		screenshots: [
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-dashboard',
				'RingCentral analytics dashboard in Akazio Cloud Archive'
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-sms',
				'RingCentral SMS conversation displayed in Akazio Cloud Archive'
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-transcription',
				'RingCentral phone call and transcription displayed in Akazio Cloud Archive'
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-team-messaging',
				'RingCentral Team Messaging (Glip) conversation displayed in Akazio Cloud Archive'
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-video',
				'RingCentral Video meeting displayed in Akazio Cloud Archive'
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-high-volume-sms',
				'RingCentral High Volume SMS collection displayed in Akazio Cloud Archive'
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-configure',
				'RingCentral connection setup demonstration Akazio Cloud Archive'
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-compliance',
				`How Akazio's compliance workflow can be applied to RingCentral data`
			),
			createScreenshot(
				'ring_central',
				'akazio-ringcentral-identity-mapping',
				`How Akazio's identity mapping features can be applied to RingCentral data`
			),
		],
		liteAdvert: true,
		liteUserPrice: ListPricingUserRingCentral,

		partnerElement: <RingCentralPartnerElement />,
		partnerLink: RC_APP_GALLERY_LINK,
	},
	{
		type: ConnectorTypeEnum.GMail,
		link: LinkPaths.CONNECTOR_GMAIL,
		name: 'GMail',
		icon: (s) => `/icons/gmail_${s}.png`,
		title: getTitle('GMail'),
		description: getDescription('GMail'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.Email],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all Google Workspace emails (internal, external, BCC and
				distribution groups), as well as other communication channels (e.g. Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Google Workspace (formerly GSuite) is Google's collection of business tools, of which GMail is the
					email offering. With roughly 1.5 billion global users, GMail has become a ubiquitous presence in the
					modern workday. However, to ensure that your organization can exploit all the benefits of the
					service, while remaining compliant in the face of increasing regulatory pressure (eg MiFID II / the
					FINRA Rules on Digital Communications), you should choose a compliance archiving platform that makes
					the capture and storage of such data easy.
				</p>
				<p>
					Akazio Cloud Archive offers an integrated web platform to manage the capture, secure storage, on
					on-going compliance and legal monitoring of all communication channels. It can be cloud deployed to
					a location in your local jurisdiction.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Multi-Medium',
				text: 'Many compliance archiving solutions are limited to emails only. Akazio is not one of them. We maintain the original format of all data uploaded to the platform, so if the conversation moves from email to IM to chat your users can follow it with ease, and assess communications with its full context at their fingertips.',
			},
			{
				title: 'Secure Storage',
				text: 'Our secure cloud platform encrypts data both at-rest and in-transit, and financial institutions can take advantage of persisting their data under tamper-proof (WORM) conditions.',
			},
			{
				title: 'Compliance & Legal Workflows',
				text: 'All files and communications uploaded to the Cloud Archive can be monitored & managed using our integrated compliance and legal workflows - automatically tag documents as soon as they are processed to reduce the workload on your compliance team.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureJournaling],
		liteAdvert: true,
		liteUserPrice: ListPricingUserEmail,
	},
	{
		type: ConnectorTypeEnum.SalesForceEmail,
		link: LinkPaths.CONNECTOR_SALES_FORCE_EMAIL,
		name: 'Salesforce Email',
		shortName: 'Salesforce',
		icon: (s) => `/icons/salesforce_${s}.png`,
		title: getTitle('Salesforce Email'),
		description: getDescription('Salesforce Email'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.Email],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all Salesforce emails, as well as other communication
				channels (e.g. Exchange, Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Salesforce has made itself known as a must-have tool for businesses when looking to manage sales,
					leads and customer relations. However the power of such tools must be measured against the ability
					of an organization to effectively archive and monitor business communications.
				</p>
				<p>
					Akazio Cloud Archive can capture and archive all your communication mediums, allowing your business
					to adhere to the increasing burden of regulations (MiFID II / FINRA / HIPAA) with the minimum of
					configuration. All files can be persisted in tamper-proof storage in a secure cloud platform
					accessible from a clean web front-end.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Full Message Context',
				text: 'Akazio maintains all message context and metadata from the files it stores, exposing all of it to a powerful search function that allows users to interrogate TBs of data for keywords and trends in seconds.',
			},
			{
				title: 'Multi Channel',
				text: 'The platform is not limited to only email; archive files and messages from all your diverse communication channels in one single cloud platform, and follow conversations as they move from email to chat.',
			},
			{
				title: 'Secure Archive',
				text: 'Our foremost concern is maintaining the security and integrity of the data under management. Rest assured that your files are protected with strong encryption (both at-rest and in-transit), and access to the platform is locked-down with SSO, IP safelisting and regular penetration assessments.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureJournaling],
	},
	{
		type: ConnectorTypeEnum.RedTailEmail,
		link: LinkPaths.CONNECTOR_REDTAIL_EMAIL,
		name: 'Redtail Email',
		shortName: 'Redtail',
		icon: (s) => `/icons/redtail-email_${s}.png`,
		title: getTitle('Redtail Email'),
		description: getDescription('Redtail Email'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.Email],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all Redtail emails, as well as other communication channels
				(e.g. Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Redtail email is a platform designed to serve the financial industry, with strong functionality to
					link with CRM systems, while ensuring that all email communications remain compliant, with limited
					archiving capabilities built-in.
				</p>
				<p>
					Akazio Cloud Archive can extend the capture, archive and compliance functionality of Redtail email,
					to ensure that your organization stays on-top of regulations regarding the collection, storage and
					monitoring of specific channels within your business workflow.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Secure Storage',
				text: 'Our cloud platform securely stores communication data in tamper-proof (WORM) storage with encryption both at-rest and in-transit. Strong data access policies, including IP safelisting and SSO integration, guarantee your confidential data is protected to a high degree.',
			},
			{
				title: 'Integrated Compliance Workflow',
				text: 'Once uploaded to the archive, your users can employ a powerful search and full compliance workflow to tag, review, and automatically alert to possible policy breaches.',
			},
			{
				title: 'Legal & eDiscovery',
				text: 'All files and metadata are persisted in their original format, so exporting any pertinent data for legal or eDiscovery reasons can easily be achieved using the integrated case management functions of the platform.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureJournaling],
	},
	{
		type: ConnectorTypeEnum.InstantBloomberg,
		link: LinkPaths.CONNECTOR_INSTANTBLOOMBERG,
		name: 'Instant Bloomberg',
		shortName: 'IB',
		icon: (s) => `/icons/instant_bloomberg_${s}.png`,
		title: getTitle('Instant Bloomberg'),
		description: getDescription('Instant Bloomberg'),
		connectorLinkToTypes: [ConnectorLinkToTypeEnum.Email, ConnectorLinkToTypeEnum.InstantMessage],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all Bloomberg chat messages, as well as other communication
				channels (e.g. email, Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Instant Bloomberg is a chat tool integrated with the Bloomberg Terminal, and allows users of the
					terminal to converse while incorporating crucial deal details.
				</p>
				<p>
					Akazio Cloud Archive is able to capture all Instant Bloomberg conversations, and store them in
					tamper-proof WORM storage, allowing organizations to adhere to their regulatory obligations with the
					minimum of fuss.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'SFTP Capture',
				text: 'Akazio processes daily communication dumps from the BB-SFTP, and upload all channel and attachment data to the Akazio Cloud Archive.',
			},
			{
				title: 'Identity Mapping',
				text: 'Associate members of an IB chat with other communication channels, as users move between chat, email, SMS, etc. Match with either their Bloomberg or corporate email.',
			},
			{
				title: 'Secure Archive',
				text: 'Our foremost concern is maintaining the security and integrity of the data under management. Rest assured that your files are protected with strong encryption (both at-rest and in-transit), and access to the platform is locked-down with SSO, IP safelisting and regular penetration assessments.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CapturePush],
	},
	{
		type: ConnectorTypeEnum.WhatsApp,
		link: LinkPaths.CONNECTOR_WHATSAPP,
		name: 'WhatsApp',
		icon: (s) => `/icons/whatsapp_${s}.png`,
		title: getTitle('WhatsApp'),
		description: getDescription('WhatsApp'),
		connectorLinkToTypes: [],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all WhatsApp chat messages, as well as other communication
				channels (e.g. email, Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					WhatsApp is an end-to-end encrypted messaging service used extensively in Europe and the USA. It can
					handle both one-to-one and group chats, as well as phone calls and video calls. It was sold to
					Facebook in 2014, and since then has made further in-roads into business use with the introduction
					of a Business API, and increasing adoption of BYOD work policies.
				</p>
				<p>
					Akazio Cloud Archive can facilitate your employees' compliant use of WhatsApp by capturing and
					archiving the WhatsApp chats used for business purposes. Personal communications can be excluded
					from capture using our opt-in agent model.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Agent Capture',
				text: 'Capture only the conversations that users have identified as business-relevant using our opt-in agent model.',
			},
			{
				title: 'All media',
				text: 'Capture all text, images, videos and recordings from selected group conversations. Store them securely in the Akazio Cloud Archive for compliance monitoring and eDiscovery.',
			},
			{
				title: 'Legal & eDiscovery',
				text: 'All files and metadata are persisted in their original format, so exporting any pertinent data for legal or eDiscovery reasons can easily be achieved using the integrated case management functions of the platform.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureContact],
		isBeta: true,
	},
	{
		type: ConnectorTypeEnum.Signal,
		link: LinkPaths.CONNECTOR_SIGNAL,
		name: 'Signal',
		icon: (s) => `/icons/signal_${s}.png`,
		title: getTitle('Signal'),
		description: getDescription('Signal'),
		connectorLinkToTypes: [],
		subheader: (
			<HeroPara>
				Akazio Cloud Archive can capture and archive all Signal chat messages, as well as other communication
				channels (e.g. email, Teams, Slack).
			</HeroPara>
		),
		blurb: (
			<BlurbWrapper>
				<p>
					Signal is a cross-platform messaging service, supporting one-to-one and group messages and phone
					calls. All messages are end-to-end encrypted, and the app includes a mechanism for users to
					independently verify the identity of their contacts and the integrity of the data channel.
				</p>
				<p>
					Akazio Cloud Archive can facilitate your employees' compliant use of Signal by capturing and
					archiving the chats used for business purposes. Personal communications can be excluded from capture
					using our opt-in agent model.
				</p>
			</BlurbWrapper>
		),
		connectorFunctionalityItems: [
			{
				title: 'Agent Capture',
				text: 'Capture only the conversations that users have identified as business-relevant using our opt-in agent model.',
			},
			{
				title: 'All media',
				text: 'Capture all text, images, videos and recordings from selected group conversations. Store them securely in the Akazio Cloud Archive for compliance monitoring and eDiscovery.',
			},
			{
				title: 'Legal & eDiscovery',
				text: 'All files and metadata are persisted in their original format, so exporting any pertinent data for legal or eDiscovery reasons can easily be achieved using the integrated case management functions of the platform.',
			},
		],
		connectorSnippets: [ConnectorSnippetEnum.CaptureContact],
		isBeta: true,
	},
	getRingCentralVariation(
		ConnectorTypeEnum.Telus,
		LinkPaths.CONNECTOR_TELUS,
		'TELUS',
		'TELUS',
		(s) => `telus_${s}.png`,
		'akazio-rc-telus-mock.webp',
		'Canada',
		'a'
	),
	getRingCentralVariation(
		ConnectorTypeEnum.AttOfficeAtHand,
		LinkPaths.CONNECTOR_ATT_OFFICE_AT_HAND,
		'AT&T Office@Hand',
		'AT&T',
		(s) => `att-office-at-hand_${s}.png`,
		'akazio-rc-att-mock.webp',
		'the USA',
		'an'
	),
	getRingCentralVariation(
		ConnectorTypeEnum.AvayaCloudOffice,
		LinkPaths.CONNECTOR_AVAYA_CLOUD_OFFICE,
		'Avaya Cloud Office',
		'Avaya',
		(s) => `avaya-cloud-office_${s}.png`,
		'akazio-rc-avaya-mock.webp',
		'the USA',
		'an'
	),
	getRingCentralVariation(
		ConnectorTypeEnum.BtCloudWork,
		LinkPaths.CONNECTOR_BT_CLOUD_WORK,
		'BT Cloud Work',
		'Cloud Work',
		(s) => `bt-cloud-work_${s}.png`,
		undefined,
		'the UK',
		'a'
	),
	getRingCentralVariation(
		ConnectorTypeEnum.AtosUnifyOffice,
		LinkPaths.CONNECTOR_ATOS_UNITY_OFFICE,
		'Atos Unity Office',
		'Atos',
		(s) => `atos-unify-office_${s}.png`,
		undefined,
		'France and Germany',
		'an'
	),
];

export const ConnectorList = ConnectorListInternal.filter((x) => {
	if (x.type === ConnectorTypeEnum.WhatsApp && !ENABLE_WHATSAPP_PAGE) return false;
	if (x.type === ConnectorTypeEnum.Signal && !ENABLE_SIGNAL_PAGE) return false;
	if (x.type === ConnectorTypeEnum.InstantBloomberg && !ENABLE_INSTANT_BBG_PAGE) return false;

	return true;
});

const ConnectorDictionaryInternal: { [key: string]: ConnectorDictionaryItem } = {};
ConnectorList.forEach((x) => {
	ConnectorDictionaryInternal[x.type] = x;
});

export const ConnectorDictionary = ConnectorDictionaryInternal;
