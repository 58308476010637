import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { MQ } from '../style/MediaQueryHelpers';

const AkazioTitleStyle = styled.div`
	align-items: baseline;
	line-height: 1;
	display: flex;
	flex-direction: row;
	margin-top: 3px;
`;

const CloudWrapper: FunctionComponent = ({ children }) => <AkazioTitleStyle>{children}</AkazioTitleStyle>;

const AkazioTitle = styled.div`
	font-family: 'Comfortaa', Sans-Serif;
	color: rgb(0, 168, 150);
`;

const AkazioIcon = styled.svg`
	margin-top: 5px;
	margin-bottom: 8px;
	height: 40px;

	${MQ.W640} {
		height: 30px;
	}
`;

const StandAloneAkazioTitle = styled(AkazioTitle)`
	font-size: 45px;
`;

const iconAndText = () => {
	return (
		<AkazioIcon height={100} viewBox="0 0 480 100" xmlns="http://www.w3.org/2000/svg">
			<circle cx={50} cy={50} r={50} fill="#00a896" />
			<path
				d="M77.001 49.401l-3.4 1.6q0 7.4-3.35 13.35-3.35 5.95-9.1 9.4a24.368 24.368 0 01-11.139 3.389 29.656 29.656 0 01-1.911.061 27.065 27.065 0 01-8.669-1.356 24.409 24.409 0 01-4.931-2.294q-6-3.65-9.5-9.95a28.024 28.024 0 01-3.41-11.711 34.031 34.031 0 01-.09-2.489 30.004 30.004 0 011.22-8.682 26.242 26.242 0 012.43-5.618q3.65-6.3 9.95-9.95a27.298 27.298 0 0112.25-3.593 32.963 32.963 0 011.95-.057 28.612 28.612 0 019.357 1.498 26.092 26.092 0 014.693 2.152q6.25 3.65 9.9 9.95 3.65 6.3 3.75 14.3zm-27.7 20.1a19.964 19.964 0 006.595-1.067 18.254 18.254 0 003.305-1.533q4.4-2.6 6.9-7.2a20.778 20.778 0 002.45-8.719 24.99 24.99 0 00.05-1.581 23.003 23.003 0 00-.769-6.05 19.516 19.516 0 00-1.731-4.3q-2.5-4.55-6.9-7.2a18.617 18.617 0 00-9.048-2.634 22.313 22.313 0 00-.852-.016 19.572 19.572 0 00-6.745 1.141 18.104 18.104 0 00-3.155 1.509q-4.4 2.65-7 7.2a19.841 19.841 0 00-2.508 8.213 24.413 24.413 0 00-.092 2.137 21.497 21.497 0 001.093 6.93 19.578 19.578 0 001.507 3.37q2.6 4.6 7 7.2a18.843 18.843 0 008.878 2.577 22.64 22.64 0 001.022.023zm19.1 3v-16.5l1.9-8.8 6.7 2.2v23.1a4.677 4.677 0 01-.199 1.395 3.711 3.711 0 01-1.051 1.655 4.319 4.319 0 01-2.938 1.149 5.557 5.557 0 01-.112.001 5.124 5.124 0 01-1.428-.19 3.976 3.976 0 01-1.672-.96 3.831 3.831 0 01-1.151-2.309 5.362 5.362 0 01-.049-.741z"
				vectorEffect="non-scaling-stroke"
				stroke="none"
				strokeLinecap="round"
				fontSize={12}
				strokeWidth={0}
				fill="#fff"
			/>
			<path stroke="#00a896" strokeWidth={3} strokeLinecap="round" d="M120 15v70" />
			<text x={140} y={85} fill="#00a896" fontFamily="Comfortaa" fontSize={100}>
				{'akazio'}
			</text>
		</AkazioIcon>
	);
};

type AkazioIconProps = {};

export const CompactAkazioIcon: React.FC<AkazioIconProps> = (props) => {
	return (
		<CloudWrapper>
			{iconAndText()}
			{/* <AkazioIcon src="/akazio-icon/akazio_icon_name.svg" height={'100%'} alt="akazio" style={{maxHeight: 40}} /> */}
		</CloudWrapper>
	);
};

export const CompactAkazioTitleElement: React.FC<AkazioIconProps> = (props) => {
	return <StandAloneAkazioTitle>akazio</StandAloneAkazioTitle>;
};
