import { scroller, animateScroll as scroll } from 'react-scroll';

export const scrollToElement = (elementName: string): void => {
	//console.log("SCROLLING TO " + elementName);
	scroller.scrollTo(elementName, {
		duration: 800,
		delay: 0,
		smooth: 'easeInOutQuart',
		offset: -70,
	});
};

export const scrollToHashLink = (hashLink: string, scrollToTopFirst?: boolean): void => {
	//console.log("SCROLLING TO " + hashLink);
	if (!hashLink) {
		scroll.scrollToTop({
			duration: 400,
			delay: 0,
			smooth: 'easeInOutQuart',
			offset: -70,
		});
	} else {
		if (scrollToTopFirst === true) {
			scroll.scrollToTop({
				duration: 0,
				delay: 0,
				smooth: 'easeInOutQuart',
				offset: 0,
			});

			setTimeout(() => {
				scroller.scrollTo(hashLink.substr(1), {
					duration: 800,
					delay: 0,
					smooth: 'easeInOutQuart',
					offset: -70,
				});
			}, 400);
		} else {
			scroller.scrollTo(hashLink.substr(1), {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart',
				offset: -70,
			});
		}
	}
};
