import React from 'react';
import { Helmet } from 'react-helmet';
import { LegalLinkPaths } from '../pages/legal/Links';
import { MetadataLookup, MetadataMap, FallbackMetadata } from './Metadata';

type HelmetWrapperProps = {
	canonicalLink: string;
	lookup?: MetadataLookup[];
	lookupDefault?: MetadataLookup;
};

const HelmetWrapper: React.FC<HelmetWrapperProps> = (props) => {
	let md = props.lookup?.find((x) => x.link === props.canonicalLink) ?? MetadataMap[props.canonicalLink];

	if (!md && props.lookupDefault) {
		md = props.lookupDefault;
	}

	if (props.canonicalLink.startsWith(LegalLinkPaths.TERMS_RAW)) {
		md = {
			title: 'Akazio | Terms',
			description: 'Akazio Inc legal information.',
			noIndex: true,
		};
	}

	if (process.env.NODE_ENV === 'development') {
		// WHAT MAKES A GOOD TITLE / DESCRIPTION?? --> https://support.google.com/webmasters/answer/35624?hl=en
		// Generally recommended title length is between 50 and 60 characters (max 600 pixels).
		// Keep your page description between 110 and 160 characters, although Google can sometimes show longer snippets.

		// Alert in dev mode if you are not doing a good job

		if (!md) {
			alert(`This page (${props.canonicalLink}) does not have a entry in the metadata dictionary!!`);
		} else if (md.noIndex) {
			// do not warn on no index pages about length
		} else if (md.title.length < 40 || md.title.length > 70) {
			// i know this is 70 and below is 60
			alert(
				`This title (${md.title}) length=${md.title.length} ideally needs to be between 40 and 60 characters long for SEO`
			);
		} else if (md.description.length < 110 || md.description.length > 160) {
			alert(
				`This Description (${md.description}) length=${md.description.length} ideally needs to be between 110 and 160 characters long for SEO`
			);
		}
	}

	md = md ?? FallbackMetadata;

	return (
		<>
			<Helmet>
				<title>{md.title}</title>
				<link rel="canonical" href={`https://www.akazio.com${props.canonicalLink}`} />
				<meta name="description" content={md.description} />

				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:site" content="@AkazioInc" />
				<meta name="twitter:title" content={md.title} />
				<meta name="twitter:description" content={md.description} />
				<meta name="twitter:image" content={`https://www.akazio.com${md.image ?? FallbackMetadata.image}`} />

				<meta property="og:url" content={`https://www.akazio.com${props.canonicalLink}`} />
				<meta property="og:title" content={md.title} />
				<meta property="og:description" content={md.description} />
				<meta property="og:image" content={`https://www.akazio.com${md.image ?? FallbackMetadata.image}`} />
				<meta
					property="og:image:secure_url"
					content={`https://www.akazio.com${md.image ?? FallbackMetadata.image}`}
				/>
				<meta property="og:type" content={'website'} />

				{md.noIndex && <meta name="robots" content="noindex" />}
			</Helmet>
			{props.children}
		</>
	);
};
export default HelmetWrapper;
