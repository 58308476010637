import React from 'react';
import styled from 'styled-components';
import { ConsentManager, openConsentManager } from '@segment/consent-manager';
import { Link } from 'react-router-dom';
import { LegalLinkPaths, GeoLocEnum } from '../pages/legal/Links';

const CookieWrapper = styled.div`
	position: fixed;
	bottom: 0px;
	width: 100%;
	flex-wrap: wrap;
	min-height: 0px;
`;

export default function () {
	const bannerContent = (
		<p style={{ padding: 15, fontSize: 18 }}>
			We use cookies (and other similar technologies) to collect data to improve your experience on our site.
			<br /> By using our website, you’re agreeing to the collection of data as described in our{' '}
			<Link to={LegalLinkPaths.TERMS_PRIVACY_POLICY(GeoLocEnum.us)} target="_blank">
				Privacy Policy
			</Link>
			.<br />
		</p>
	);
	const bannerSubContent = (
		<p style={{ paddingBottom: 15, fontSize: 18 }}>You can change your preferences at any time. </p>
	);
	const preferencesDialogTitle = 'Website Data Collection Preferences';
	const preferencesDialogContent =
		'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.';
	const cancelDialogTitle = 'Are you sure you want to cancel?';
	const cancelDialogContent =
		'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.';

	return (
		<CookieWrapper>
			<ConsentManager
				writeKey="UJTGmAxSuLuFDI4AfHcXy5t7WaCRyW2J"
				shouldRequireConsent={() => true}
				bannerContent={bannerContent}
				bannerSubContent={bannerSubContent}
				preferencesDialogTitle={preferencesDialogTitle}
				preferencesDialogContent={preferencesDialogContent}
				cancelDialogTitle={cancelDialogTitle}
				cancelDialogContent={cancelDialogContent}
				implyConsentOnInteraction={false}
				closeBehavior={(categories: any) => ({
					...categories,
					advertising: false,
				})}
			/>
		</CookieWrapper>
	);
}

export const OpenCookiePreferences = () => {
	openConsentManager();
};
