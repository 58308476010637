import React from 'react';
import styled from 'styled-components';

const ButtonStyle = styled.div<{ filled: boolean; bordered: boolean; size: 'small' | 'middle' | 'large' }>`
	line-height: 1.5715;
	position: relative;
	display: inline-block;
	white-space: nowrap;
	text-align: center;
	background-image: none;
	box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	user-select: none;
	touch-action: manipulation;
	font-size: 14px;
	border-radius: 2px;
	color: rgba(0, 0, 0, 0.65);
	background-color: #fff;

	border: 2px solid ${(props) => (props.bordered ? 'var(--bar-bg-color-dark1)' : 'transparent')} !important;
	font-size: 14px;

	// large
	padding: ${(props) => (props.size === 'large' ? 6.4 : props.size === 'middle' ? 4 : 1)}px
		${(props) => (props.size === 'small' ? 5 : 15)}px;
	border-radius: 2px;

	${(props) =>
		props.filled &&
		`
		color: white;
		background: ${props.color ?? 'var(--bar-bg-color-dark1)'};
	`}

	:hover {
		color: #1db5a1;
		background: #fff;
	}
`;

const ButtonInternal = styled.div<{ size: 'small' | 'middle' | 'large' }>`
	margin-top: ${(props) => (props.size === 'large' ? 1 : -1)}px;
`;

type ButtonProps = {
	size?: 'small' | 'middle' | 'large';
	filled?: boolean;
	bordered?: boolean;
	onClick?: () => void;
};

const Button: React.FC<ButtonProps> = (props) => {
	return (
		<ButtonStyle
			onClick={props.onClick}
			filled={!!props.filled}
			bordered={!!props.bordered}
			size={props.size ?? 'large'}
		>
			<ButtonInternal size={props.size ?? 'large'}>{props.children}</ButtonInternal>
		</ButtonStyle>
	);
};

export default Button;
