export enum GeoLocEnum {
	gb = 'gb',
	us = 'us',
}

export type LegalRouteProps = {
	loc: GeoLocEnum | undefined;
};

const ResolveLoc = (loc: GeoLocEnum | undefined) => loc?.toString() ?? GeoLocEnum.us.toString();

export class LegalLinkPaths {
	static TERMS_RAW = '/terms';

	static TERMS_ROUTE = (loc?: string) => `${LegalLinkPaths.TERMS_RAW}/${loc ?? ':loc'}`;

	static TERMS_LICENCE_AGREEMENT_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/general-terms`;
	static TERMS_LICENCE_AGREEMENT_SCHEDULE_1_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/1-service-description`;
	static TERMS_LICENCE_AGREEMENT_SCHEDULE_2_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/2-acceptable-use-policy`;
	static TERMS_LICENCE_AGREEMENT_SCHEDULE_3_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/3-data-processing-schedule`;

	static TERMS_PRIVACY_POLICY_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/privacy-policy`;
	static TERMS_DATA_RETENTION_POLICY_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/data-retention-policy`;
	static TERMS_DATA_DELETION_POLICY_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/data-deletion-policy`;
	static TERMS_DATA_STORAGE_POLICY_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/data-storage-policy`;
	static TERMS_SUBPROCESSORS_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/subprocessors`;

	static TERMS_SECURITY_REPORT_VULNERABILITY_ROUTE = `${LegalLinkPaths.TERMS_ROUTE()}/security/report-a-vulnerability`;

	// TERMS - LINKS
	static TERMS = (loc: GeoLocEnum | undefined) => LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc));

	static TERMS_LICENCE_AGREEMENT = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/general-terms`;
	static TERMS_LICENCE_AGREEMENT_SCHEDULE_1 = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/1-service-description`;
	static TERMS_LICENCE_AGREEMENT_SCHEDULE_2 = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/2-acceptable-use-policy`;
	static TERMS_LICENCE_AGREEMENT_SCHEDULE_3 = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/3-data-processing-schedule`;

	static TERMS_PRIVACY_POLICY = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/privacy-policy`;
	static TERMS_DATA_RETENTION_POLICY = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/data-retention-policy`;
	static TERMS_DATA_DELETION_POLICY = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/data-deletion-policy`;
	static TERMS_DATA_STORAGE_POLICY = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/data-storage-policy`;
	static TERMS_SUBPROCESSORS = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/subprocessors`;

	static TERMS_SECURITY_REPORT_VULNERABILITY = (loc: GeoLocEnum | undefined) =>
		`${LegalLinkPaths.TERMS_ROUTE(ResolveLoc(loc))}/security/report-a-vulnerability`;
}
