import React from 'react';
import styled from 'styled-components';
import { ProductList } from './ElementLists';
import { RightSquareFilled, InfoCircleFilled } from '@ant-design/icons';
import { MQ } from '../../style/MediaQueryHelpers';

const Wrapper = styled.div`
	margin-top: 30px;

	${MQ.W640} {
		margin-top: 15px;
	}
`;

const ElementWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

const BulletIcon = styled(RightSquareFilled)`
	font-size: 26px;
	margin-left: 30px;
	margin-right: 10px;

	${MQ.W640} {
		font-size: 20px;
		margin-left: 15px;
		margin-right: 5px;
	}
`;

const LinkIcon = styled(InfoCircleFilled)`
	font-size: 26px;
	margin-left: 30px;
	margin-right: 10px;

	${MQ.W640} {
		font-size: 20px;
		margin-left: 15px;
		margin-right: 5px;
	}
`;

export const ConnectorIconWrapper = styled.div`
	padding-top: 15px;
	cursor: pointer;

	:hover {
		transform: scale(1.08);
	}
`;

export const ConnectorIcon = styled.div`
	height: 60px;
	position: relative;
`;

export const ConnectorImg = styled.img`
	margin-left: 40px;
	margin-right: 40px;
`;

export const ConnectorIconBetaLabel = styled.div`
	background: #45e827;
	position: absolute;
	bottom: 2px;
	right: 35px;
	padding: 0px 3px;
	border-radius: 5px;
	color: white;
	font-size: 12px;
`;

type ElementListElementProps = {
	list: ProductList[];
};

const ElementListElement: React.FC<ElementListElementProps> = (props) => {
	return (
		<>
			{props.list.map((x) => (
				<Wrapper key={x.title}>
					<h4>{x.title}</h4>
					{x.elements.map((e) => (
						<ElementWrapper key={e}>
							<BulletIcon />
							<p>{e}</p>
						</ElementWrapper>
					))}
					{x.link && (
						<ElementWrapper key={x.link}>
							<LinkIcon />
							<a href={x.link}>{x.linkText}</a>
						</ElementWrapper>
					)}
				</Wrapper>
			))}
		</>
	);
};
export default ElementListElement;
