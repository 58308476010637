import React from 'react';
import styled from 'styled-components';
import { Element } from 'react-scroll';
import { CenteredContentContainer } from './Content';
import { MQ } from '../style/MediaQueryHelpers';

const Box = styled.div<{
	color: string;
	backcolor: string;
	backimage?: string;
	compact: number;
}>`
	background-color: ${(props) => props.backcolor};
	background-image: ${(props) => props.backimage};
	color: ${(props) => props.color};
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 50px;

	& h4 {
		color: ${(props) => props.backcolor};
		background-color: ${(props) => props.color};
		width: 300px;
		padding: 10px;
		font-size: 20px;
		font-variant: small-caps;
		text-align: center;
		border-radius: 3px;
		border-top-left-radius: 10px;
		border-bottom-right-radius: 10px;

		${MQ.W640} {
			font-size: 16px;
			padding: 5px;
			width: 200px;
		}
	}

	${MQ.W640} {
		padding: 10px;
		padding-left: 0px;
		padding-right: 0px;
	}
`;

// for SEO sometimes we want a h1 title, sometimes a h2
const TitleH1 = styled.h1<{ withsubtitle?: boolean }>`
	color: inherit;
	font-size: 42px;
	text-align: center;
	${(props) => (props.withsubtitle ? 'margin-bottom: 0;' : '')}

	${MQ.W640} {
		font-size: 30px;
	}
`;

const Title = styled.h2<{ withsubtitle?: boolean }>`
	color: inherit;
	font-size: 42px;
	text-align: center;
	${(props) => (props.withsubtitle ? 'margin-bottom: 0;' : '')}

	${MQ.W640} {
		font-size: 30px;
	}
`;

const SubTitle = styled.h3`
	color: inherit;
	font-size: 30px;
	text-align: center;

	${MQ.W640} {
		font-size: 24px;
	}
`;

const Summary = styled(CenteredContentContainer)`
	color: inherit;
	font-size: 18px;
	font-style: italic;
`;

export enum ColoredBarColor {
	None = 'None',
	Dark1 = 'Dark1',
	Dark2 = 'Dark2',
	Medium1 = 'Medium1',
	Medium2 = 'Medium2',
	Light1 = 'Light1',
	Light2 = 'Light2',

	Splash = 'Splash',
}

export const backgroundColorDic = {
	[ColoredBarColor.None]: 'var(--bar-bg-color-none)',
	[ColoredBarColor.Dark1]: 'var(--bar-bg-color-dark1)',
	[ColoredBarColor.Dark2]: 'var(--bar-bg-color-dark2)',
	[ColoredBarColor.Medium1]: 'var(--bar-bg-color-medium1)',
	[ColoredBarColor.Medium2]: 'var(--bar-bg-color-medium2)',
	[ColoredBarColor.Light1]: 'var(--bar-bg-color-light1)',
	[ColoredBarColor.Light2]: 'var(--bar-bg-color-light2)',
	[ColoredBarColor.Splash]: 'linear-gradient(to right, rgba(227, 248, 229), rgba(217, 228, 241))',
};

const fontColorDic = {
	[ColoredBarColor.None]: 'var(--bar-color-none)',
	[ColoredBarColor.Dark1]: 'var(--bar-color-dark1)',
	[ColoredBarColor.Dark2]: 'var(--bar-color-dark2)',
	[ColoredBarColor.Medium1]: 'var(--bar-color-medium1)',
	[ColoredBarColor.Medium2]: 'var(--bar-color-medium2)',
	[ColoredBarColor.Light1]: 'var(--bar-color-light1)',
	[ColoredBarColor.Light2]: 'var(--bar-color-light2)',
	[ColoredBarColor.Splash]: '#3F3E3E',
};

type ColoredBarProps = {
	fgColorString?: string;
	bgColorString?: string;
	color: ColoredBarColor;
	title: string;
	subTitle?: string;
	makeTitleH1?: boolean;
	summary?: string;
	anchor?: string;
	element: JSX.Element;
	compact?: boolean;
	blog?: boolean;
};

const ColoredBar: React.FC<ColoredBarProps> = (props) => {
	const titleElement = props.anchor ? (
		<Element name={props.anchor}>
			{props.makeTitleH1 ? (
				<TitleH1 withsubtitle={!!props.subTitle}>{props.title}</TitleH1>
			) : (
				<Title withsubtitle={!!props.subTitle}>{props.title}</Title>
			)}
			{props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
		</Element>
	) : (
		<>
			{props.makeTitleH1 ? (
				<TitleH1 withsubtitle={!!props.subTitle}>{props.title}</TitleH1>
			) : (
				<Title withsubtitle={!!props.subTitle}>{props.title}</Title>
			)}
			{props.subTitle && <SubTitle>{props.subTitle}</SubTitle>}
		</>
	);

	let backgroundColor = backgroundColorDic[props.color];
	let backgroundImage = undefined;
	if (props.color === ColoredBarColor.Splash) {
		backgroundColor = 'transparent';
		backgroundImage = backgroundColorDic[props.color];
	}

	return (
		<Box
			color={props.fgColorString ?? fontColorDic[props.color]}
			backcolor={props.bgColorString ?? backgroundColor}
			backimage={backgroundImage}
			compact={props.compact === true ? 1 : 0}
		>
			<div className={`colored-bar-contents ${props.blog ? 'colored-bar-contents-blog' : ''}`}>
				{titleElement}
				{props.title && (
					<div
						style={{
							backgroundColor: backgroundColorDic[ColoredBarColor.Dark1],
							height: '2px',
							width: '100px',
							marginLeft: 'auto',
							marginRight: 'auto',
						}}
					/>
				)}
				{props.summary && <Summary>{props.summary}</Summary>}
				{props.element}
			</div>
		</Box>
	);
};

export default ColoredBar;
