import React from 'react';
import styled from 'styled-components';
import { CompactAkazioTitleElement } from '../../AkazioIcon';

const Wrapper = styled.div<{ visible: boolean }>`
	display: ${(props) => (props.visible ? 'flex' : 'none')};
	flex-direction: column;
	position: absolute;
	left: 100vw;
	width: 100vw;
	top: 70px;
	height: calc(100vh - 70px);
	box-shadow: 0 0 10px #85888c;
	background-color: white;
	-webkit-font-smoothing: antialiased;
	transform-origin: 0% 0%;
	transform: translate(-100%, 0);
	transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`;

const BottomIcon = styled.div`
	position: absolute;
	bottom: 0px;
	right: 25px;
`;

type MobileDrawerProps = {
	visible: boolean;
	onClose: () => void;
};

const MobileDrawer: React.FC<MobileDrawerProps> = (props) => {
	return (
		<Wrapper visible={props.visible}>
			{props.children}
			<BottomIcon>
				<CompactAkazioTitleElement />
			</BottomIcon>
		</Wrapper>
	);
};

export default MobileDrawer;
