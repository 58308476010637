import { UpCircleOutlined, DownCircleOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { MQ } from '../../style/MediaQueryHelpers';
import FlagChooser from './FlagChooser';
import { LegalLinkPaths, LegalRouteProps } from './Links';

type NavigationProps = {};

const Header = styled.div`
	font-weight: bold;
	font-size: 15px;
`;

const StyledLink = styled(Link)<{ hl: number }>`
	font-size: 14px;
	font-weight: ${(props) => (props.hl ? 'bold' : 'normal')};
`;

const Spacer = styled.div`
	margin-bottom: 20px;
`;

const NavLinkWrapper = styled.div`
	${MQ.W640} {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
`;

const NavLinkDiv = styled.div`
	${MQ.W640} {
		margin-left: 10px;
		margin-top: 2px;
		padding-left: 10px;
		border-left: solid 3px #cccccc;
		width: 100%;
	}
`;

const DesktopOnly = styled.div`
	${MQ.W640} {
		display: none;
	}
`;

const MobileOnly = styled.div`
	display: none;
	${MQ.W640} {
		display: block;
		margin-bottom: 20px;
	}
`;

const MobileNavDiv = styled.div`
	margin-bottom: 15px;
`;

const Navigation: React.FC<NavigationProps & RouteComponentProps<LegalRouteProps>> = (props) => {
	const [mobNavOpen, setMobNavOpen] = useState(false);

	const geoLoc = props.match.params.loc;

	const currentRoute = props.history.location.pathname;
	const NavLink = (text: string, link: string) => {
		const isSelected = link === currentRoute ? 1 : 0;
		return (
			<NavLinkDiv>
				<StyledLink to={link} hl={isSelected}>
					{text}
				</StyledLink>
			</NavLinkDiv>
		);
	};

	const navigation = (
		<div>
			<FlagChooser selected={geoLoc} />
			<Header>Terms</Header>
			<NavLinkWrapper>
				{NavLink('General Terms', LegalLinkPaths.TERMS_LICENCE_AGREEMENT(geoLoc))}
				{NavLink('Service Description (S1)', LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_1(geoLoc))}
				{NavLink('Acceptable Use Policy (S2)', LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_2(geoLoc))}
				{NavLink('Data Processing Schedule (S3)', LegalLinkPaths.TERMS_LICENCE_AGREEMENT_SCHEDULE_3(geoLoc))}
			</NavLinkWrapper>
			<Spacer />

			<Header>Policies</Header>
			<NavLinkWrapper>
				{NavLink('Privacy Policy', LegalLinkPaths.TERMS_PRIVACY_POLICY(geoLoc))}
				{NavLink('Data Retention Policy', LegalLinkPaths.TERMS_DATA_RETENTION_POLICY(geoLoc))}
				{NavLink('Data Deletion Policy', LegalLinkPaths.TERMS_DATA_DELETION_POLICY(geoLoc))}
				{NavLink('Data Storage Policy', LegalLinkPaths.TERMS_DATA_STORAGE_POLICY(geoLoc))}
				{NavLink('Akazio Subprocessors', LegalLinkPaths.TERMS_SUBPROCESSORS(geoLoc))}
			</NavLinkWrapper>
			<Spacer />

			<Header>Security</Header>
			<NavLinkWrapper>
				{NavLink('Report a Vulnerability', LegalLinkPaths.TERMS_SECURITY_REPORT_VULNERABILITY(geoLoc))}
			</NavLinkWrapper>
			<Spacer />
		</div>
	);

	return (
		<>
			<DesktopOnly>{navigation}</DesktopOnly>
			<MobileOnly onClick={() => setMobNavOpen((x) => !x)}>
				<MobileNavDiv>{mobNavOpen ? <DownCircleOutlined /> : <UpCircleOutlined />} Navigation</MobileNavDiv>
				{mobNavOpen && navigation}
			</MobileOnly>
		</>
	);
};

export default withRouter(Navigation);
