import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import Button from './shared/antd-replacements/Button';
import { LinkPaths } from './Constants';

type BookDemoButtonProps = {
	isForMobile: boolean;
	isFilled?: boolean;
	isBordered?: boolean;
	textOverride?: string;
	linkOverride?: string;
	buttonIcon?: JSX.Element;
};

const BookDemoButton: React.FC<BookDemoButtonProps & RouteComponentProps> = (props) => {
	const text = props.textOverride ?? (props.isForMobile ? 'FREE TRIAL' : 'START A FREE TRIAL');
	return (
		<Link to={props.linkOverride ?? LinkPaths.CONTACT_SALES}>
			<Button
				filled={!!props.isFilled}
				bordered={!!props.isBordered || !!props.isFilled}
				size={props.isForMobile ? 'middle' : 'large'}
			>
				{props.buttonIcon} {text}
			</Button>
		</Link>
	);
};

export default withRouter(BookDemoButton);
