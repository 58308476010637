import React, { lazy, Suspense } from 'react';
import { MetadataLookup } from '../../metadata/Metadata';
import { Loading } from '../shared/Loading';

const SuspendedRoute = (a: JSX.Element) => <Suspense fallback={<Loading />}>{a}</Suspense>;

const Blog0001 = lazy(() => import('./blog-posts/Blog0001'));
const Blog0002 = lazy(() => import('./blog-posts/Blog0002'));
const Blog0003 = lazy(() => import('./blog-posts/Blog0003'));
const Blog0004 = lazy(() => import('./blog-posts/Blog0004'));
const Blog0005 = lazy(() => import('./blog-posts/Blog0005'));
const Blog0006 = lazy(() => import('./blog-posts/Blog0006'));
const Blog0007 = lazy(() => import('./blog-posts/Blog0007'));
const Blog0008 = lazy(() => import('./blog-posts/Blog0008'));

const Editorial0001 = lazy(() => import('./editorials/Editorial0001'));
const Editorial0002 = lazy(() => import('./editorials/Editorial0002'));

const News0001 = lazy(() => import('./news/News0001'));
const News0002 = lazy(() => import('./news/News0002'));

export enum PostTypeEnum {
	Blog = 'Blog',
	Editorial = 'Editorial',
	News = 'News',
}

export type BlogPost = {
	id: number;
	title: string; // used for metadata tags
	onPageTitle: string; // used for display on page
	description: string; // used for metadata tags
	onPageDescription?: string; // used for display on page (default to desc)
	image: string; // this will show up in linkedin / twitter etc
	image_thumb: string;
	link: string;
	date: Date;
	element: JSX.Element;
	postType?: PostTypeEnum; // default to Blog
	inactive?: boolean;
	author?: string;
};

export type EditorialPost = {
	editorialColorSplash?: string;
};

export type NewsPost = {
	newsImage?: string;
};

// CHECKLIST
// 	> add any new blog to the site map
//	> test by naving to blog page in dev mode that the title and description are the right length

const BlogPostListInternal: (BlogPost & EditorialPost & NewsPost)[] = [
	{
		id: 1,
		title: 'Akazio | Blog | COVID-19: Archiving, Compliance Monitoring & Discovery',
		onPageTitle: 'COVID-19 Effect: Archiving, Compliance Monitoring and Discovery',
		description:
			'How has COVID has affected the tactics organizations need to use when dealing with their archiving, compliance monitoring and discovery concerns.',
		image: '/blog/images/covid-2.jpg',
		image_thumb: '/blog/images/covid-2_thumb.jpg',
		link: '/blog/covid-19-effect',
		date: new Date(2020, 7, 25),
		element: SuspendedRoute(<Blog0001 />),
	},
	{
		id: 2,
		title: 'Akazio | Blog | Top 5 Reasons for Archiving Communication Data',
		onPageTitle: 'Top 5 Reasons for Archiving Communication Data: Email, Instant Messaging and Social',
		description:
			'Why is message archiving important for firms of all sizes, and why you should find a solution to monitoring emails, instant messages and social.',
		image: '/blog/images/archive.jpg',
		image_thumb: '/blog/images/archive_thumb.jpg',
		link: '/blog/top-5-reasons-for-archiving',
		date: new Date(2020, 10, 3),
		element: SuspendedRoute(<Blog0002 />),
	},
	{
		id: 4,
		title: 'Akazio | Video | RegTech Summit Video Pitch (Data Residency)',
		onPageTitle: 'Akazio pitch at the A-Team RegTech Virtual Summit 2020',
		description:
			"Watch Akazio's one-minute video pitch, presented at the A-Team RegTech Virtual Summit 2020, explaining how our strict data residency controls can help you.",
		image: '/blog/images/akazio-a-team-startup-pitch.png',
		image_thumb: '/blog/images/akazio-a-team-startup-pitch_thumb.png',
		link: '/blog/regtech-summit-pitch-2020',
		date: new Date(2020, 10, 17),
		element: SuspendedRoute(<Blog0004 />),
	},
	{
		id: 3,
		title: 'Akazio | Blog | Ultimate Archive and Discovery Checklist',
		onPageTitle: 'Checklist for the Ultimate Archive and Discovery Solution',
		description:
			'The Akazio team provide an insight into the key components for an effective cloud archive and discovery solution for the next 10+ years.',
		image: '/blog/images/ultimate-archive-checklist-splash.png',
		image_thumb: '/blog/images/ultimate-archive-checklist-splash_thumb.png',
		link: '/blog/ultimate-archive-checklist',
		date: new Date(2020, 11, 15),
		element: SuspendedRoute(<Blog0003 />),
	},
	{
		id: 5,
		title: 'Akazio | Blog | The Importance of Data Residency & Archiving',
		onPageTitle: 'Data Residency: The importance of knowing where your data is stored',
		description:
			'Understand the importance of data residency when it comes to cloud archiving. Gain insight into the key drivers you should consider when selecting a solution.',
		image: '/blog/images/data-residency-splash.png',
		image_thumb: '/blog/images/data-residency-splash_thumb.png',
		link: '/blog/importance-of-data-residency',
		date: new Date(2021, 0, 18),
		element: SuspendedRoute(<Blog0005 />),
	},
	{
		id: 6,
		title: 'Akazio | Blog | MiFID II: Communication Record-Keeping Requirements',
		onPageTitle: 'MiFID II: Communication Record-Keeping Requirements',
		description:
			'Read about MiFID II regulations in the context of communication record-keeping. Akazio can help Financial Services firms satisfy the data archiving legislation.',
		image: '/blog/images/mifid-ii-splash.png',
		image_thumb: '/blog/images/mifid-ii-splash_thumb.png',
		link: '/blog/mifid-ii-requirements',
		date: new Date(2021, 1, 17),
		element: SuspendedRoute(<Blog0006 />),
	},
	{
		id: 7,
		title: 'Akazio | Blog | RingCentral & Akazio: SMS, Messaging & Voice',
		onPageTitle: 'RingCentral & Akazio: SMS, Messaging & Voice',
		description:
			'Read about how Akazio can streamline RingCentral SMS and voice capture, archive and searching for organizations of any size.',
		image: '/blog/images/akazio-rc-splash.jpg',
		image_thumb: '/blog/images/akazio-rc-splash_thumb.jpg',
		link: '/blog/akazio-ringcentral',
		date: new Date(2021, 2, 30),
		element: SuspendedRoute(<Blog0007 />),
	},
	{
		id: 8,
		title: 'Akazio | Blog | Top 6 Reasons for Slack Archiving',
		onPageTitle: 'Top 6 Reasons for Slack Archiving',
		description:
			'RingCentral & Akazio: Check-out the latest blog on the key reasons for archiving and discovery of Slack content.',
		image: '/blog/images/akazio-slack-splash.webp',
		image_thumb: '/blog/images/akazio-slack-splash_thumb.webp',
		link: '/blog/akazio-slack-archiving',
		date: new Date(2021, 5, 24),
		element: SuspendedRoute(<Blog0008 />),
		author: 'Deepan Hari, Sales Director',
	},

	{
		id: 1000,
		title: 'The Ultimate Guide to WORM Storage | Akazio',
		onPageTitle: 'The Ultimate Guide to WORM Storage',
		description:
			'What is WORM storage, how does it work, and why should small and large organizations alike use it when designing an archiving plan?',
		image: '/blog/images/placeholder-splash.png',
		image_thumb: '/blog/images/placeholder-splash.png',
		link: '/blog/the-ultimate-guide-to-worm-storage',
		date: new Date(2021, 3, 2),
		element: SuspendedRoute(<Editorial0001 />),

		postType: PostTypeEnum.Editorial,
		editorialColorSplash: '#dc6060',
		inactive: true,
	},
	{
		id: 1001,
		title: '10 Steps to Archive Readiness for SMBs | Akazio',
		onPageTitle: '10 Steps to a Successful Archiving Strategy for Small Businesses',
		description:
			"Developing a successful archiving strategy for a small business doesn't have to be tricky, just so long as you have clear, achievable goals in mind",
		onPageDescription:
			"Developing a successful archiving strategy for a small business doesn't have to be tricky, or cost the Earth, just so long as you have clear, achievable goals in mind.",
		image: '/blog/images/ten-steps-for-smbs-splash.webp',
		image_thumb: '/blog/images/ten-steps-for-smbs-splash_thumb.webp',
		link: '/blog/10-steps-to-archive-readiness-for-smbs',
		date: new Date(2021, 3, 21),
		element: SuspendedRoute(<Editorial0002 />),

		postType: PostTypeEnum.Editorial,
		editorialColorSplash: '#79dc60',
	},

	{
		id: 2000,
		title: 'Deepan Hari joins as Sales Director | News | Akazio',
		onPageTitle: 'Akazio appoints Deepan Hari as Sales Director',
		description:
			'News: Akazio welcomes Deepan Hari as Sales Director (Global Markets) to step up sales in domestic and foreign markets.',
		onPageDescription:
			"Deepan will lead Sales and Marketing to grow an already strong presence in the US, UK and Europe, and extend Akazio's markets to Asia and The Middle East.",
		image: '/blog/images/deepan-sales-lead-splash.webp',
		image_thumb: '/blog/images/deepan-sales-lead-splash_thumb.webp',
		link: '/blog/news-deepan-hari-joins-as-sales-director',
		date: new Date(2021, 3, 14),
		element: SuspendedRoute(<News0001 />),

		postType: PostTypeEnum.News,
		newsImage: '/blog/images/deepan-image.webp',
	},
	{
		id: 2001,
		title: 'Cloud Archive Lite Launch | News | Akazio',
		onPageTitle: 'Product Launch: Cloud Archive Lite',
		description:
			'Akazio launches Cloud Archive Lite - the perfect archiving solution for SMBs who require a secure, modern web archive.',
		image: '/blog/images/product-launch-akazio-lite-splash.webp',
		image_thumb: '/blog/images/product-launch-akazio-lite-splash_thumb.webp',
		link: '/blog/news-product-launch-cloud-archive-lite',
		date: new Date(2021, 3, 21),
		element: SuspendedRoute(<News0002 />),

		postType: PostTypeEnum.News,
		newsImage: '/blog/images/temp-search-mockup.webp',
	},
];

export const BlogPostList = BlogPostListInternal.filter((x) => !x.inactive);

export const BlogPostHomeMetadata: MetadataLookup = {
	title: 'Akazio | Blog | Ideas, news and updates from the Akazio team',
	description:
		'Keep up-to-date with regtech news, compliance archiving tips, opinions and how-tos from the Akazio team and their partners. ',
	link: '/blog',
};
