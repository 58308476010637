import React, { useState, useEffect, lazy, Suspense } from 'react';
import styled from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import BookDemoButton from './BookDemoButton';
import { LinkPaths } from './Constants';
import { CompactAkazioIcon } from './AkazioIcon';
import { MQ } from '../style/MediaQueryHelpers';
import { Loading } from './shared/Loading';
import { Link } from 'react-router-dom';
import MobileDrawer from './shared/antd-replacements/MobileDrawer';

const AkazioFooter = lazy(() => import('./AkazioFooter'));
const AkazioSocialFooter = lazy(() => import('./AkazioSocialFooter'));

const LayoutContainer = styled.div`
	background: #fff;
`;

const Affix = styled.div`
	z-index: 99;
	position: sticky;
	top: 0px;
`;

const ContentContainer = styled.div`
	background: #fff;
	padding-bottom: 100px;

	${MQ.W640} {
		padding-bottom: 40px;
	}
`;

const CompactAkazioIconWrapper = styled.div`
	display: flex;
	flex-direction: row;
	cursor: pointer;
`;

const DesktopMenuItem = styled.div<{ selected: number }>`
	color: #666;
	font-size: 17px;
	margin-right: 20px;
	:hover {
		color: var(--masthead-color);
	}
`;

const MobileMenuItem = styled(DesktopMenuItem)<{ selected: number }>`
	color: #666;
	font-size: 22px;
	margin-left: 25px;
	margin-top: 20px;
`;

const MenuDivWrapper = styled.div`
	height: 80px;
	padding-top: 10px;
	background-color: white;
	opacity: 0.97;
	//border-bottom: 1px solid var(--bar-bg-color-dark1);
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);

	${MQ.W640} {
		height: 70px;
	}
`;

const MenuDiv = styled.div`
	max-width: 1200px;
	margin: auto;
	display: flex;
	flex-direction: row;
	padding-left: 20px;
	padding-right: 20px;

	${MQ.W640} {
		padding-left: 10px;
		padding-right: 10px;
	}
`;

const TitleMenuContainer = styled.div`
	display: flex;
	flex-direction: row;
	line-height: 48px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 10px;

	${MQ.W980} {
		display: none;
	}
`;

const TitleDemoButtonContainer = styled.div<{ ismobile: number }>`
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 20px;
	display: ${(props) => (props.ismobile <= 0 ? 'inline' : 'none')};

	${MQ.W640} {
		display: ${(props) => (props.ismobile > 0 ? 'inline' : 'none')};
	}
`;

const HamburgerOpenIcon = styled(MenuOutlined)`
	display: none;
	margin-top: auto;
	margin-bottom: auto;

	${MQ.W980} {
		display: flex;
		margin-right: 10px;
	}
`;

const HamburgerCloseIcon = styled(CloseOutlined)`
	display: none;
	margin-top: auto;
	margin-bottom: auto;

	${MQ.W980} {
		display: flex;
		margin-right: 10px;
	}
`;

// FOOTER -------------------------

const FooterWrapper = styled.div`
	padding: 24px 50px;
	background-color: var(--footer-bg-color) !important;
	color: var(--footer-color) !important;

	h2 {
		color: var(--footer-color) !important;
		font-size: 13px;
	}

	a {
		color: var(--footer-link-color) !important;
		font-size: 12px;
	}
`;

const SocialFooterWrapper = styled.div`
	padding: 24px 50px;
	background: var(--social-footer-bg-color) !important;
	color: var(--social-footer-color) !important;
	padding-top: 15px !important;
	padding-bottom: 5px !important;
`;

// FOOTER -------------------------

type ContainerProps = {
	element: JSX.Element | null;
};

type AllProps = ContainerProps & RouteComponentProps;

const Container: React.FC<AllProps> = (props) => {
	const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
	const [selectedMenuItems, setSelectedMenuItems] = useState([LinkPaths.HOME]);

	useEffect(() => {
		if (props.location.pathname === LinkPaths.HOME) {
			setSelectedMenuItems([LinkPaths.HOME]);
		} else if (props.location.pathname === LinkPaths.PRODUCTS) {
			setSelectedMenuItems([LinkPaths.PRODUCTS]);
		} else if (props.location.pathname === LinkPaths.SOLUTIONS) {
			setSelectedMenuItems([LinkPaths.SOLUTIONS]);
		} else if (props.location.pathname === LinkPaths.PRICING) {
			setSelectedMenuItems([LinkPaths.PRICING]);
		} else if (props.location.pathname.startsWith(LinkPaths.BLOG)) {
			setSelectedMenuItems([LinkPaths.BLOG]);
		} else {
			setSelectedMenuItems([]);
		}
	}, [props.location.pathname]);

	return (
		<LayoutContainer>
			<Affix>
				<MenuDivWrapper>
					<MenuDiv>
						<Link to={LinkPaths.HOME} style={{ textDecoration: 'none', color: 'unset' }}>
							<CompactAkazioIconWrapper>
								<CompactAkazioIcon />
							</CompactAkazioIconWrapper>
						</Link>

						<div style={{ flexGrow: 1 }} />

						<TitleMenuContainer>
							<Link to={LinkPaths.HOME}>
								<DesktopMenuItem selected={selectedMenuItems.includes(LinkPaths.HOME) ? 1 : 0}>
									HOME
								</DesktopMenuItem>
							</Link>
							<Link to={LinkPaths.PRODUCTS}>
								<DesktopMenuItem selected={selectedMenuItems.includes(LinkPaths.PRODUCTS) ? 1 : 0}>
									PRODUCTS
								</DesktopMenuItem>
							</Link>
							<Link to={LinkPaths.SOLUTIONS}>
								<DesktopMenuItem selected={selectedMenuItems.includes(LinkPaths.SOLUTIONS) ? 1 : 0}>
									SOLUTIONS
								</DesktopMenuItem>
							</Link>
							<Link to={LinkPaths.PRICING}>
								<DesktopMenuItem selected={selectedMenuItems.includes(LinkPaths.PRICING) ? 1 : 0}>
									PRICING
								</DesktopMenuItem>
							</Link>
							<Link to={LinkPaths.BLOG}>
								<DesktopMenuItem selected={selectedMenuItems.includes(LinkPaths.BLOG) ? 1 : 0}>
									BLOG
								</DesktopMenuItem>
							</Link>
						</TitleMenuContainer>

						<TitleDemoButtonContainer ismobile={0}>
							<BookDemoButton isForMobile={false} isBordered={true} />
						</TitleDemoButtonContainer>

						<TitleDemoButtonContainer ismobile={1}>
							<BookDemoButton isForMobile={true} isBordered={true} />
						</TitleDemoButtonContainer>

						{hamburgerMenuOpen ? (
							<HamburgerCloseIcon onClick={() => setHamburgerMenuOpen(false)} />
						) : (
							<HamburgerOpenIcon onClick={() => setHamburgerMenuOpen(true)} />
						)}

						<MobileDrawer visible={hamburgerMenuOpen} onClose={() => setHamburgerMenuOpen(false)}>
							<Link to={LinkPaths.HOME}>
								<MobileMenuItem
									selected={selectedMenuItems.includes(LinkPaths.HOME) ? 1 : 0}
									onClick={() => setHamburgerMenuOpen(false)}
								>
									HOME
								</MobileMenuItem>
							</Link>
							<Link to={LinkPaths.PRODUCTS}>
								<MobileMenuItem
									selected={selectedMenuItems.includes(LinkPaths.PRODUCTS) ? 1 : 0}
									onClick={() => setHamburgerMenuOpen(false)}
								>
									PRODUCTS
								</MobileMenuItem>
							</Link>
							<Link to={LinkPaths.SOLUTIONS}>
								<MobileMenuItem
									selected={selectedMenuItems.includes(LinkPaths.SOLUTIONS) ? 1 : 0}
									onClick={() => setHamburgerMenuOpen(false)}
								>
									SOLUTIONS
								</MobileMenuItem>
							</Link>
							<Link to={LinkPaths.PRICING}>
								<MobileMenuItem
									selected={selectedMenuItems.includes(LinkPaths.PRICING) ? 1 : 0}
									onClick={() => setHamburgerMenuOpen(false)}
								>
									PRICING
								</MobileMenuItem>
							</Link>
							<Link to={LinkPaths.BLOG}>
								<MobileMenuItem
									selected={selectedMenuItems.includes(LinkPaths.BLOG) ? 1 : 0}
									onClick={() => setHamburgerMenuOpen(false)}
								>
									BLOG
								</MobileMenuItem>
							</Link>
						</MobileDrawer>
					</MenuDiv>
				</MenuDivWrapper>
			</Affix>
			<ContentContainer>{props.element}</ContentContainer>
			<FooterWrapper>
				<Suspense fallback={<Loading />}>
					<AkazioFooter />
				</Suspense>
			</FooterWrapper>
			<SocialFooterWrapper>
				<Suspense fallback={<Loading />}>
					<AkazioSocialFooter />
				</Suspense>
			</SocialFooterWrapper>
		</LayoutContainer>
	);
};

export default withRouter(Container);
