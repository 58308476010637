import { LinkPaths } from '../pages/Constants';

export type Metadata = {
	title: string;
	description: string;
	image?: string; // this will be used for eg linkedin / twitter
	noIndex?: boolean;
};

export const FallbackMetadata: Metadata = {
	title: 'Akazio - scalable cloud-based capture and archive solution',
	description: 'Scalable cloud-based capture and archive solution for eDiscovery and compliance',
	image: '/images/connectors-to-ui.webp',
};

export type MetadataLookup = Metadata & {
	link: string;
};

export const MetadataMap: { [key: string]: Metadata } = {
	[LinkPaths.HOME]: {
		title: 'Akazio | Secure Cloud Archiving and Monitoring Platform',
		description:
			'The Akazio Cloud Archive simplifies compliance archiving, & offers a secure platform for firms to perform compliance monitoring and legal eDiscovery operations',
		image: '/images/meta-tags/a-cloud.jpg',
	},
	[LinkPaths.PRODUCTS]: {
		title: 'Akazio | Product | Cloud Compliance for monitoring and eDiscovery',
		description:
			"Discover the features of Akazio's secure cloud platform that make it an essential tool for compliance archiving for organizations of all sizes",
		image: '/images/meta-tags/laptop-user.jpg',
	},
	[LinkPaths.LITE_PRODUCT]: {
		title: 'Akazio | Cloud Archive Lite | Cost-effective archiving for SMBs',
		description:
			'Learn more about the only platform that provides exactly the archiving features small and medium businesses require, for a price that makes sense',
		image: '/images/meta-tags/laptop-user.jpg',
	},
	[LinkPaths.CONNECTOR_PRODUCT]: {
		title: 'Akazio | Connectors | Cloud Compliance for monitoring and eDiscovery',
		description:
			'Find out how Akazio can integrate its channel connectors with external systems and 3rd party analytics providers.',
		image: '/images/meta-tags/laptop-user.jpg',
	},
	[LinkPaths.CONNECTORS_PAGE]: {
		title: 'Akazio | Connectors | Cloud Compliance for monitoring and eDiscovery',
		description:
			"Browse the supported connector channels available with Akazio's market leading archiving platform, and discover how quickly you can integrate",
		image: '/images/meta-tags/laptop-user.jpg',
	},
	[LinkPaths.FULL_PRODUCT]: {
		title: 'Akazio | Cloud Archive | Enterprise compliance archiving & discovery',
		description:
			"Discover the features of Akazio's secure cloud platform that make it an essential tool for compliance archiving for organizations of all sizes",
		image: '/images/meta-tags/laptop-user.jpg',
	},
	[LinkPaths.SOLUTIONS]: {
		title: 'Akazio | Solutions | Cloud archive for financial, legal, health & more',
		description:
			'Read how the financial, legal & health industries can utilize Akazio for regulation-compliance, eDiscovery, HR, GDPR / Freedom of Information, and more',
		image: '/images/meta-tags/london-skyline.jpg',
	},
	[LinkPaths.SOLUTIONS_FINANCIAL_COMPLIANCE]: {
		title: 'Akazio | Solutions | Financial Compliance',
		description:
			'Archiving and compliance solution for financial service companies needing to monitor eComms data and meet SEC 17a-4 regulations',
		image: '/images/meta-tags/london-skyline.jpg',
	},
	[LinkPaths.SOLUTIONS_EARLY_CASE_ASSESSMENT]: {
		title: 'Akazio | Solutions | Early Case Assessment',
		description:
			'Archiving and compliance solution that enables quick upload, filtering and export of communication data for GDPR and FOI requests',
		image: '/images/meta-tags/london-skyline.jpg',
	},
	[LinkPaths.SOLUTIONS_SALES_CALL_MONITORING]: {
		title: 'Akazio | Solutions | Sales Call Monitoring',
		description:
			'Archiving and compliance solution with transcription options for monitoring sales calls for mis-selling, script adherence and litigation',
		image: '/images/meta-tags/london-skyline.jpg',
	},
	[LinkPaths.SOLUTIONS_HR_LITIGATION]: {
		title: 'Akazio | Solutions | HR & Litigation Archive',
		description:
			'Archiving and compliance solution that streamlines the collection, monitoring and search of communication data for HR and legal teams in SMBs',
		image: '/images/meta-tags/london-skyline.jpg',
	},
	[LinkPaths.SOLUTIONS_SECURE_BACKUP]: {
		title: 'Akazio | Solutions | Secure Long-Term Backup',
		description:
			'Archiving and compliance solution for long-term secure storage of eComms data, allowing for search and export of raw files and metadata',
		image: '/images/meta-tags/london-skyline.jpg',
	},
	[LinkPaths.SOLUTIONS_FOI]: {
		title: 'Akazio | Solutions | GDRP and FOI Requests',
		description:
			'Archiving and compliance solution that streamlines the processing of GDPR, FOI requests and DSARs for small and medium-sized businesses',
		image: '/images/meta-tags/london-skyline.jpg',
	},

	[LinkPaths.PRICING]: {
		title: 'Akazio | Pricing | Competitive and volume-driven pricing plans',
		description:
			'Competitive volume-based pricing model for email, IM, voice and social archiving that suits large, medium and small organizations',
		image: '/images/meta-tags/london-skyline.jpg',
	},
	[LinkPaths.CAREERS]: {
		title: 'Akazio | Careers | View our current opportunities and perks',
		description:
			'Come and join a talented group of technology enthusiasts who want to create great software, and see what we have to offer',
		image: '/images/meta-tags/work-team.jpg',
	},
	[LinkPaths.CONTACT_SALES]: {
		title: 'Akazio | Contact Us | Get in touch for a demo or free trial',
		description:
			'Contact us to organize a demo of the cloud archive, or claim a free no-commitment trial of the compliance monitoring platform',
	},
	[LinkPaths.CONTACT_SALES_THANKYOU]: {
		title: 'Akazio | Thank you | A member of the team will be in touch soon',
		description:
			'Thank you for contacting us - a member of the team will be in touch shortly to organize your demo or free trial',
		noIndex: true,
	},
	[LinkPaths.CONTACT_SUPPORT]: {
		title: 'Akazio | Support | Raise a support ticket',
		description: 'If you are an existing customer with an issue or question, please let us know',
		noIndex: true,
	},
	[LinkPaths.CONTACT_SUPPORT_THANKYOU]: {
		title: 'Akazio | Thank you | A member of the team will be in touch soon',
		description:
			'Thank you for contacting us - a member of the team will be in touch shortly to organize your demo or free trial',
		noIndex: true,
	},
	[LinkPaths.DATA_RESIDENCY]: {
		title: 'Akazio | Data Residency | Know where in the world your data is stored',
		description:
			'Akazio guarantee that your data will only be stored where you want it to be - whether that is in Europe, the USA, or anywhere else in the world.',
		image: '/images/meta-tags/old-style-globe.jpg',
	},
	[LinkPaths.SECURITY]: {
		title: 'Akazio | Security | Secure cloud archive with strong encryption',
		description:
			'Akazio protects your data - from IP safelisting and SSO access controls, to strong encryption at-rest and in-transit, to industry complaint WORM storage',
		image: '/images/meta-tags/awsd-keys.jpg',
	},
	[LinkPaths.EMAIL_ARCHIVING]: {
		title: 'Akazio | Email Archiving | Email archive with regional deployment',
		description:
			'Securely archive your emails and attachments to a modern web archive in your local region to meet data residency requirements.',
		image: '/images/email_archiving_masthead.webp',
	},
	[LinkPaths.IM_ARCHIVING]: {
		title: 'Akazio | IM Archiving | Messaging archive with regional deployment',
		description:
			'Securely archive your IMs and attachments to a modern web archive in your local region to meet data residency requirements.',
		image: '/images/im_archiving_masthead.webp',
	},
	[LinkPaths.AV_ARCHIVING]: {
		title: 'Akazio | Audio / Video Archiving | Archive with regional deployment',
		description:
			'Securely archive your audio and video eComms data to a modern web archive in your local region to meet data residency requirements.',
		image: '/images/im_archiving_masthead.webp',
	},
};
