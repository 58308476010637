import styled from 'styled-components';
import { ConnectorIconBetaLabel } from '../../products/ElementListElement';
import { MQ } from './../../../style/MediaQueryHelpers';

export const PageWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;

	${MQ.W640} {
		flex-wrap: wrap;
	}
`;

export const PageContent = styled.div`
	flex-basis: 2px;
	flex-grow: 2;
	margin: 80px;
	margin-top: 50px;

	${MQ.W640} {
		margin: 10px;
		margin-top: 50px;
	}
`;

export const PageContentH1 = styled.h1`
	font-size: 48px;
	margin: 0px;

	${MQ.W640} {
		font-size: 32px;
		display: none;
	}
`;

export const PageContentH2 = styled.h2`
	font-size: 32px;
	font-variant: small-caps;

	${MQ.W640} {
		font-size: 24px;
	}
`;

export const StickyIconWrapper = styled.div`
	top: 100px;
	position: sticky;
	align-self: flex-start;
	flex-basis: 1px;
	flex-grow: 1;
	text-align: center;

	${MQ.W640} {
		min-width: 100%;
		position: relative;
		top: 30px;
	}
`;

export const ConnectorIconBetaLabelBig = styled(ConnectorIconBetaLabel)`
	font-size: 14px;
	position: unset;
	width: 60px;
	margin-left: auto;
	margin-right: auto;
`;

export const BlurbWrapper = styled.div`
	font-size: 18px;
`;

export const HeroPara = styled.h2`
	margin: 1em 0;
`;

export const FunctionalityItemList = styled.ul`
	margin-top: 50px;
`;

export const FunctionalityListItem = styled.li`
	font-size: 16px;
	margin-bottom: 10px;
`;

export const SnippetWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 100px;
	justify-content: space-evenly;

	${MQ.W640} {
		margin-top: 50px;
	}
`;

export const SnippetContent = styled.div`
	width: 45%;
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
	color: unset;

	${MQ.W640} {
		width: 100%;
	}
`;

export const SnippetTextContent = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SnippetTitle = styled.div`
	font-size: 16px;
	font-weight: bold;
`;

export const SnippetText = styled.div`
	font-size: 16px;
`;

export const OtherConnectorsCard = styled.div`
	margin-top: 100px;

	${MQ.W640} {
		margin-top: 40px;
	}
`;

export const OtherConnectorsContent = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;
`;

export const OtherConnectorsContentLinkWrapper = styled.div`
	margin: 10px;
	text-align: center;
	width: 110px;
`;

export const OtherConnectorsImageWrapper = styled.div`
	height: 50px;
	position: relative;
`;

export const BetaAlertWrapper = styled.div`
	text-align: center;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	padding: 10px;
	border: solid 1px #45e82766;
`;

export const BetaAlertText = styled.p`
	margin-top: 15px;
`;
