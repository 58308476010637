export const DESKTOP_CONTENT_PADDING = 50;

export class LinkPaths {
	static HOME = '/';

	static PRODUCTS = '/product';

	static LITE_PRODUCT = `${LinkPaths.PRODUCTS}/cloud-archive-lite`;
	static CONNECTOR_PRODUCT = `${LinkPaths.PRODUCTS}/stand-alone-connectors`;
	static CONNECTORS_PAGE = `${LinkPaths.PRODUCTS}/connectors`;

	static FULL_PRODUCT = `${LinkPaths.PRODUCTS}/cloud-archive`;
	// static FULL_PRODUCT_CONNECT_HASH = 'connect';
	// static FULL_PRODUCT_CONNECT = `${LinkPaths.FULL_PRODUCT}#${LinkPaths.FULL_PRODUCT_CONNECT_HASH}`;
	// static FULL_PRODUCT_ARCHIVE_HASH = 'archive';
	// static FULL_PRODUCT_ARCHIVE = `${LinkPaths.FULL_PRODUCT}#${LinkPaths.FULL_PRODUCT_ARCHIVE_HASH}`;
	// static FULL_PRODUCT_SUPERVISE_HASH = 'supervise';
	// static FULL_PRODUCT_SUPERVISE = `${LinkPaths.FULL_PRODUCT}#${LinkPaths.FULL_PRODUCT_SUPERVISE_HASH}`;
	// static FULL_PRODUCT_DISCOVER_HASH = 'discover';
	// static FULL_PRODUCT_DISCOVER = `${LinkPaths.FULL_PRODUCT}#${LinkPaths.FULL_PRODUCT_DISCOVER_HASH}`;

	static SOLUTIONS = '/solutions';
	// static SOLUTIONS_FINANCIAL_HASH = 'financial';
	// static SOLUTIONS_FINANCIAL = `${LinkPaths.SOLUTIONS}#${LinkPaths.SOLUTIONS_FINANCIAL_HASH}`;
	// static SOLUTIONS_LEGAL_HASH = 'legal';
	// static SOLUTIONS_LEGAL = `${LinkPaths.SOLUTIONS}#${LinkPaths.SOLUTIONS_LEGAL_HASH}`;
	// static SOLUTIONS_GDPR_HASH = 'gdpr';
	// static SOLUTIONS_GDPR = `${LinkPaths.SOLUTIONS}#${LinkPaths.SOLUTIONS_GDPR_HASH}`;
	// static SOLUTIONS_HR_HASH = 'hr';
	// static SOLUTIONS_HR = `${LinkPaths.SOLUTIONS}#${LinkPaths.SOLUTIONS_HR_HASH}`;

	static CASE_STUDY_HASH = `casestudy`;
	static SOLUTIONS_FINANCIAL_COMPLIANCE = `${LinkPaths.SOLUTIONS}/financial-compliance`;
	static SOLUTIONS_FINANCIAL_COMPLIANCE_CASE_STUDY_HASH = `${LinkPaths.SOLUTIONS_FINANCIAL_COMPLIANCE}#${LinkPaths.CASE_STUDY_HASH}`;
	static SOLUTIONS_EARLY_CASE_ASSESSMENT = `${LinkPaths.SOLUTIONS}/early-case-assessment`;
	static SOLUTIONS_SALES_CALL_MONITORING = `${LinkPaths.SOLUTIONS}/sales-call-monitoring`;
	static SOLUTIONS_HR_LITIGATION = `${LinkPaths.SOLUTIONS}/hr-and-litigation`;
	static SOLUTIONS_SECURE_BACKUP = `${LinkPaths.SOLUTIONS}/secure-long-term-backup`;
	static SOLUTIONS_FOI = `${LinkPaths.SOLUTIONS}/gdpr-and-foi-requests`;

	static PRICING = '/pricing';
	static PRICING_LITE_PRICING_HASH = 'lite-pricing';
	static PRICING_LITE_PRICING = `${LinkPaths.PRICING}#${LinkPaths.PRICING_LITE_PRICING_HASH}`;

	static SECURITY = '/security';
	static DATA_RESIDENCY = '/data-residency';
	static EMAIL_ARCHIVING = '/email-archiving';
	static IM_ARCHIVING = '/im-archiving';
	static AV_ARCHIVING = '/audio-video-archiving';

	static CONTACT_SALES = '/contact';
	static CONTACT_SALES_THANKYOU = '/thank-you';
	static CONTACT_SUPPORT = '/support';
	static CONTACT_SUPPORT_THANKYOU = '/support-complete';

	static NEWS = '/news';

	static CAREERS = '/careers';

	static BLOG = '/blog';

	static REGULATIONS = '/regulations';

	static CONNECTOR_MSFT_TEAMS = '/connector-msft-teams';
	static CONNECTOR_OFFICE_365 = '/connector-o365';
	static CONNECTOR_MSFT_EXCHANGE = '/connector-msft-exchange';
	static CONNECTOR_GMAIL = '/connector-gmail';
	static CONNECTOR_SLACK = '/connector-slack';
	static CONNECTOR_RINGCENTRAL = '/connector-ringcentral';
	static CONNECTOR_SALES_FORCE_EMAIL = '/connector-salesforce-email';
	static CONNECTOR_REDTAIL_EMAIL = '/connector-redtail-email';
	static CONNECTOR_WHATSAPP = '/connector-whatsapp';
	static CONNECTOR_SIGNAL = '/connector-signal';
	static CONNECTOR_INSTANTBLOOMBERG = '/connector-instant-bloomberg';

	static CONNECTOR_TELUS = '/connector-telus';
	static CONNECTOR_ATT_OFFICE_AT_HAND = '/connector-att-office-at-hand';
	static CONNECTOR_AVAYA_CLOUD_OFFICE = '/connector-avaya-cloud-office';
	static CONNECTOR_BT_CLOUD_WORK = '/connector-bt-cloud-work';
	static CONNECTOR_ATOS_UNITY_OFFICE = '/connector-atos-unity-office';

	static ALTERNATIVE_SMARSH = '/alternative-smarsh';
	static ALTERNATIVE_MIMECAST = '/alternative-mimecast';
	static ALTERNATIVE_GLOBALRELAY = '/alternative-globalrelay';
}
