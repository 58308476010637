export class EmailSettings {
	static CareersEmail = 'careers@akazio.com';
	static SalesEmail = 'sales@akazio.com';
	static HiEmail = 'hi@akazio.com';
	static SupportEmail = 'support@akazio.com';
	static LegalEmail = 'legal@akazio.com';
}

export class DemoRequestSettings {
	static ReCaptchaSiteKey = '6Lf8_KwZAAAAAP35dyH41BOxSJRKx8LNmcQAN7OF';
	static EmailJsServiceId = 'mailjet';
	static EmailJsTemplateId = 'template_l9NZpL5n';
}

export class SocialMediaSettings {
	static TwitterUrl = 'https://twitter.com/AkazioInc';
	static LinkedInUrl = 'https://www.linkedin.com/company/akazio/';
}

// Experimental settings
export const ENABLE_PRICING_PAGE = true;
export const ENABLE_WHATSAPP_PAGE = true;
export const ENABLE_SIGNAL_PAGE = false;
export const ENABLE_INSTANT_BBG_PAGE = false;
