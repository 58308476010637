import React from 'react';
import {
	CloudUploadOutlined,
	TagsOutlined,
	MonitorOutlined,
	NodeIndexOutlined,
	LockOutlined,
	BulbOutlined,
	AudioOutlined,
} from '@ant-design/icons';
import { SnippetContent, SnippetTextContent, SnippetText, SnippetTitle, SnippetWrapper } from './Styles';

export type ConnectorSnippet = {
	title: string;
	text: (connectorName: string) => JSX.Element;
	icon: JSX.Element;
};

const IconStyle = {
	fontSize: 32,
	marginRight: 10,
	color: '#1FA797',
};

export enum ConnectorSnippetEnum {
	// HOW IS IT CAPTURED?
	CaptureJournaling = 'CaptureJournaling',
	CaptureApp = 'CaptureApp',
	CaptureAgent = 'CaptureAgent',
	CaptureContact = 'CaptureContact',
	CapturePush = 'CapturePush',
	// ... other capture forms ...

	// HOW OFTEN

	// GENERICS FOR ALL
	Policy = 'Policy',
	Search = 'Search',
	Context = 'Context',
	Security = 'Security',
	IntegratedArchive = 'IntegratedArchive',
	Transcription = 'Transcription',
}

const ConnectorSnippetDictionary: { [key: string]: ConnectorSnippet } = {
	[ConnectorSnippetEnum.CaptureJournaling]: {
		title: 'Journaling Capture',
		text: (n) => (
			<div>{`Communications are captured via already-present journaling functionality. Upload all ${n} data to the Akazio Cloud Archive.`}</div>
		),
		icon: <CloudUploadOutlined style={{ ...IconStyle }} />,
	},
	[ConnectorSnippetEnum.CaptureApp]: {
		title: 'App Capture',
		text: (n) => (
			<div>{`Our capture app can be installed by an administrator and will automatically capture all ${n} communication data, and upload it to the Akazio Cloud Archive.`}</div>
		),
		icon: <CloudUploadOutlined style={{ ...IconStyle }} />,
	},
	[ConnectorSnippetEnum.CaptureAgent]: {
		title: 'Agent Capture',
		text: (n) => (
			<div>{`Our capture agent can be installed by an administrator and will automatically capture all ${n} communication data, and upload it to the Akazio Cloud Archive.`}</div>
		),
		icon: <CloudUploadOutlined style={{ ...IconStyle }} />,
	},
	[ConnectorSnippetEnum.CaptureContact]: {
		title: 'Contact Capture',
		text: (n) => (
			<div>{`Users can add an Akazio contact to any communication channel that should be captured. The contact agent will automatically capture all ${n} communication data, and upload it to the Akazio Cloud Archive.`}</div>
		),
		icon: <CloudUploadOutlined style={{ ...IconStyle }} />,
	},
	[ConnectorSnippetEnum.CapturePush]: {
		title: 'Push Capture',
		text: (n) => (
			<div>{`${n} will periodically push data to a file share location, at which point Akazio will upload all communication data to the Akazio Cloud Archive.`}</div>
		),
		icon: <CloudUploadOutlined style={{ ...IconStyle }} />,
	},

	[ConnectorSnippetEnum.Policy]: {
		title: 'Compliance Workflow',
		text: (n) => (
			<div>
				Apply compliance and legal policies against each document as it is uploaded to the archive, to
				immediately alert users to high risk data, or tag documents with relevant metadata.
			</div>
		),
		icon: <TagsOutlined style={{ ...IconStyle }} />,
	},

	[ConnectorSnippetEnum.Search]: {
		title: 'Powerful Search',
		text: (n) => (
			<div>
				Akazio's powerful document &amp; chat search can sift through millions of records to highlight and
				display results in milliseconds. Search both on the content of the original file or chat, and against
				metadata added by platform users.
			</div>
		),
		icon: <MonitorOutlined style={{ ...IconStyle }} />,
	},

	[ConnectorSnippetEnum.Context]: {
		title: 'Original Context',
		text: (n) => (
			<div>
				Our connectors and archive maintain the metadata of complex types (and never flatten them to email), so
				that users can search on a rich document model, and follow conversations that transcend different
				mediums.
			</div>
		),
		icon: <NodeIndexOutlined style={{ ...IconStyle }} />,
	},

	[ConnectorSnippetEnum.Security]: {
		title: 'Secure Archive',
		text: (n) => (
			<div>
				Akazio encrypts communication data both at-rest and in-transit, and enforces strong data access
				protocols (like SSO, IP safelisting and tamper-proof WORM storage) to ensure that you confidential
				information is kept safe.
			</div>
		),
		icon: <LockOutlined style={{ ...IconStyle }} />,
	},

	[ConnectorSnippetEnum.IntegratedArchive]: {
		title: 'Integrated Platform',
		text: (n) => (
			<div>
				Archive and monitor all your communication channels in a single web-accessible platform. Follow threads
				as they move to different mediums, and investigate breaches through in-place legal case management.
			</div>
		),
		icon: <BulbOutlined style={{ ...IconStyle }} />,
	},

	[ConnectorSnippetEnum.Transcription]: {
		title: 'Transcription Services',
		text: (n) => (
			<div>
				Transcribe phone calls, video meetings and media files, and enable searching across the content of such
				communication data just like you would with email or instant messaging data.
			</div>
		),
		icon: <AudioOutlined style={{ ...IconStyle }} />,
	},
};

type ConnectorSnippetProps = {
	snippetType: ConnectorSnippetEnum;
	connectorName: string;
};
const ConnectorSnippet: React.FC<ConnectorSnippetProps> = (props) => {
	const item = ConnectorSnippetDictionary[props.snippetType];
	return (
		<SnippetContent>
			{item.icon}
			<SnippetTextContent>
				<SnippetTitle>{item.title}</SnippetTitle>
				<SnippetText>{item.text(props.connectorName)}</SnippetText>
			</SnippetTextContent>
		</SnippetContent>
	);
};

type ConnectorSnippetsProps = {
	list: ConnectorSnippetEnum[];
	connectorName: string;
};

const SnippetsApplicableForAll: ConnectorSnippetEnum[] = [
	ConnectorSnippetEnum.Search,
	ConnectorSnippetEnum.Security,
	ConnectorSnippetEnum.Context,
	ConnectorSnippetEnum.Transcription,
	ConnectorSnippetEnum.IntegratedArchive,
];

const ConnectorSnippets: React.FC<ConnectorSnippetsProps> = (props) => {
	return (
		<SnippetWrapper>
			{props.list.concat(SnippetsApplicableForAll).map((x) => (
				<ConnectorSnippet {...props} key={x} snippetType={x} />
			))}
		</SnippetWrapper>
	);
};

export default ConnectorSnippets;
