import React from 'react';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { GeoLocEnum, LegalLinkPaths } from './Links';

const Wrapper = styled.div`
	margin-bottom: 10px;
`;

const StyledFlag = styled.img<{ selected: number; hasmarginleft?: number }>`
	margin-left: ${(props) => (props.hasmarginleft ? 10 : 0)}px;
	padding: 2px;
	cursor: pointer;

	${(props) =>
		props.selected === 1
			? `
		border: 2px solid var(--masthead-title-color);
	`
			: `
		opacity: 0.2;
	`}
`;

type FlagChooserProps = {
	selected: GeoLocEnum | undefined;
};

const FlagChooser: React.FC<FlagChooserProps & RouteComponentProps> = (props) => {
	const onClick = (loc: GeoLocEnum) => {
		// nothing clever, if you change your location you back to the root
		props.history.push(LegalLinkPaths.TERMS(loc));
	};

	return (
		<Wrapper>
			<StyledFlag
				onClick={() => onClick(GeoLocEnum.us)}
				src="/icons/flag-us.png"
				alt="USA flag"
				selected={props.selected === GeoLocEnum.us || !props.selected ? 1 : 0}
			/>
			<StyledFlag
				onClick={() => onClick(GeoLocEnum.gb)}
				src="/icons/flag-gb.png"
				alt="UK flag"
				selected={props.selected === GeoLocEnum.gb ? 1 : 0}
				hasmarginleft={1}
			/>
		</Wrapper>
	);
};

export default withRouter(FlagChooser);
