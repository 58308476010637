import React from 'react';
import styled from 'styled-components';
import { MQ } from './../style/MediaQueryHelpers';

export const ContentContainer = styled.div`
	margin-top: 25px;
	font-size: 18px;

	${MQ.W980} {
		padding-left: 10%;
		padding-right: 10%;
	}

	${MQ.W640} {
		padding-left: 20px;
		padding-right: 20px;
		margin-left: 0px;
		margin-right: 0px;
		margin-top: 10px;
		font-size: 14px;
	}
`;

export const ContentContainerBigger = styled(ContentContainer)`
	font-size: 22px;
	${MQ.W640} {
		font-size: 16px;
	}
`;

export const FlexRowContentContainer = styled(ContentContainer)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
`;

export const CenteredContentContainer = styled(ContentContainer)`
	text-align: center;
`;

export const CenteredFlexRowContentContainer = styled(CenteredContentContainer)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
`;

type ContentProps = {
	centered?: boolean;
	flexRow?: boolean;
	noPadding?: boolean;
};

const Content: React.FC<ContentProps> = (props) => {
	if (props.centered) {
		return props.flexRow === true ? (
			<CenteredFlexRowContentContainer>{props.children}</CenteredFlexRowContentContainer>
		) : (
			<CenteredContentContainer>{props.children}</CenteredContentContainer>
		);
	}
	return props.flexRow === true ? (
		<FlexRowContentContainer>{props.children}</FlexRowContentContainer>
	) : (
		<ContentContainer>{props.children}</ContentContainer>
	);
};

const LeftRightContentWrapper = styled(CenteredFlexRowContentContainer)<{
	ltr: number;
}>`
	justify-content: center;
	display: flex;
	flex-wrap: ${(props) => (props.ltr > 0 ? 'wrap' : 'wrap-reverse')};

	${MQ.W980} {
		margin-left: 10%;
		margin-right: 10%;
	}

	${MQ.W640} {
		margin-left: 0;
		margin-right: 0;
	}
`;

const LeftRightContentElement = styled.div<{ pc: number; img: number }>`
	width: calc(${(props) => props.pc}%);
	text-align: left;
	margin-top: auto;
	margin-bottom: auto;

	${MQ.W980} {
		width: 100%;
		text-align: justify;
	}

	${MQ.W640} {
		width: 100%;
		text-align: justify;
	}
`;

type LeftRightContentProps = {
	left: JSX.Element;
	right: JSX.Element;
	leftToRight: boolean; // if false then we assume on mobile that RHS should be above LHS
	bigSidePc?: number;
};

export const LeftRightContent: React.FC<LeftRightContentProps> = (props) => {
	const bigSidePc = props.bigSidePc ?? 80;
	const littleSidePc = 100 - bigSidePc;

	return (
		<LeftRightContentWrapper ltr={props.leftToRight ? 1 : 0}>
			<LeftRightContentElement pc={props.leftToRight ? bigSidePc : littleSidePc} img={props.leftToRight ? 0 : 1}>
				{props.left}
			</LeftRightContentElement>
			{/* <LeftRightContentSplitter /> */}
			<LeftRightContentElement pc={props.leftToRight ? littleSidePc : bigSidePc} img={props.leftToRight ? 1 : 0}>
				{props.right}
			</LeftRightContentElement>
		</LeftRightContentWrapper>
	);
};

const ContentImageWrapper = styled.div<{ hidemob: number }>`
	text-align: center;

	${MQ.W640} {
		width: 50%;
		margin-left: auto;
		margin-right: auto;
		margin-top: 10px;
		margin-bottom: 10px;
		${(props) => (props.hidemob > 0 ? 'display: none;' : '')}
	}
`;

const ContentImage = styled.img<{ width: string; maxwidth: string }>`
	width: ${(props) => props.width};
	max-width: ${(props) => props.maxwidth};
	height: auto;

	${MQ.W640} {
		width: 100%;
		max-width: 100%;
	}
`;

export const SvgContentImage = (img: string, alt: string, hiddenOnMobile?: boolean, forceWidth?: string) => {
	return (
		<ContentImageWrapper hidemob={hiddenOnMobile === true ? 1 : 0}>
			<ContentImage src={img} alt={alt} width={forceWidth ?? '100%'} maxwidth={forceWidth ?? '150px'} />
		</ContentImageWrapper>
	);
};

export const ColoredHorizLine = styled.div<{
	maxwidth: number;
	marginvert: number;
	mobileonly: number;
}>`
	background: var(--bar-bg-color-dark1);
	height: 2px;
	margin-left: auto;
	margin-right: auto;
	max-width: ${(props) => props.maxwidth}px;
	margin-top: ${(props) => props.marginvert}px;
	margin-bottom: ${(props) => props.marginvert}px;

	display: ${(props) => (props.mobileonly > 0 ? 'none' : 'block')};
	${MQ.W640} {
		display: block;
	}
`;

export default Content;
