import { ArrowRightOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { MQ } from '../../../../style/MediaQueryHelpers';

const ImageWrapper = styled.div`
	width: 250px;

	${MQ.W640} {
		width: 200px;
	}
`;

const Img = styled.img`
	max-width: 100%;
	height: auto;
`;

const Container = styled.div`
	width: 250px;
	margin-left: auto;
	margin-right: auto;

	${MQ.W640} {
		width: 200px;
	}
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: auto auto auto;
	grid-gap: 5px;
`;

const FindUsText = styled.div`
	font-size: 11px;
`;

export const RC_APP_GALLERY_LINK = 'https://www.ringcentral.com/apps/akazio-for-ringcentral';

const RingCentralPartnerElement: React.FC = () => {
	return (
		<Container>
			<a href={RC_APP_GALLERY_LINK} target="_blank" rel="noopener noreferrer">
				<Wrapper>
					{/* <div><img src={'/icons/rc-premium-crown.svg'} alt={'RingCentral premium partner crown'}/> Premium Partner</div> */}
					<ImageWrapper>
						<Img
							src={'/icons/rc-premier-partner_250.png'}
							alt={'Akazio is a RingCentral ISV Premium Partner'}
						/>
					</ImageWrapper>
					{/* <div> Premium Partner</div> */}
					<FindUsText>Find us in the App Gallery!</FindUsText>
					<ArrowRightOutlined />
				</Wrapper>
			</a>
		</Container>
	);
};

export default RingCentralPartnerElement;
