import { AudioOutlined, MailOutlined, MessageOutlined } from '@ant-design/icons';
import React from 'react';
import styled from 'styled-components';
import { MQ } from '../../../style/MediaQueryHelpers';
import BookDemoButton from '../../BookDemoButton';
import { LinkPaths } from '../../Constants';

export enum ConnectorLinkToTypeEnum {
	InstantMessage,
	AudioVideo,
	Email,
}

type ConnectorLinkToTypePagesProps = {
	connectorLinkToTypes: ConnectorLinkToTypeEnum[];
};

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: auto auto auto;
	grid-gap: 30px;
	justify-content: center;
	justify-items: center;

	${MQ.W640} {
		grid-template-columns: 1fr;
		grid-template-rows: auto auto auto;
	}
`;

const ConnectorLinkToTypePages: React.FC<ConnectorLinkToTypePagesProps> = (props) => {
	return (
		<Wrapper>
			{props.connectorLinkToTypes.includes(ConnectorLinkToTypeEnum.Email) && (
				<BookDemoButton
					isForMobile={false}
					isBordered={false}
					isFilled={true}
					textOverride={'EMAIL ARCHIVING'}
					linkOverride={LinkPaths.EMAIL_ARCHIVING}
					buttonIcon={<MailOutlined />}
				/>
			)}
			{props.connectorLinkToTypes.includes(ConnectorLinkToTypeEnum.InstantMessage) && (
				<BookDemoButton
					isForMobile={false}
					isBordered={false}
					isFilled={true}
					textOverride={'INSTANT MESSAGE ARCHIVING'}
					linkOverride={LinkPaths.IM_ARCHIVING}
					buttonIcon={<MessageOutlined />}
				/>
			)}
			{props.connectorLinkToTypes.includes(ConnectorLinkToTypeEnum.AudioVideo) && (
				<BookDemoButton
					isForMobile={false}
					isBordered={false}
					isFilled={true}
					textOverride={'AUDIO / VIDEO ARCHIVING'}
					linkOverride={LinkPaths.AV_ARCHIVING}
					buttonIcon={<AudioOutlined />}
				/>
			)}
		</Wrapper>
	);
};

export default ConnectorLinkToTypePages;
