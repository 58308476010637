import React, { lazy } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import analytics from '@segment/analytics.js-core';
import cm from './analytics/ConsentManager';

import './App.css';

import Container from './pages/Container';
import { LinkPaths } from './pages/Constants';
import { ConnectorList } from './pages/landing-pages/connectors-and-alternatives/ConnectorTypes';
import { BlogPostList, BlogPostHomeMetadata } from './pages/blog/BlogPostsRepo';
import { ENABLE_PRICING_PAGE } from './AkazioConfig';
import { SuspendedRoute, SuspendedRouteWithLookup } from './utils/SuspendedRoutes';
import TermsPages from './pages/legal/TermsPages';
import { LegalLinkPaths } from './pages/legal/Links';

const HomePage = lazy(() => import('./pages/home/HomePage'));

const ProductsHomePage = lazy(() => import('./pages/products/ProductsHomePage'));
const FullProductPage = lazy(() => import('./pages/products/FullProductPage'));
const LiteProductPage = lazy(() => import('./pages/products/LiteProductPage'));
const ConnectorStandAlonePage = lazy(() => import('./pages/products/ConnectorStandAlonePage'));
const ConnectorsPage = lazy(() => import('./pages/products/ConnectorsPage'));
const SolutionPage = lazy(() => import('./pages/solutions/SolutionPage'));
const SolutionFinacialCompliancePage = lazy(() => import('./pages/solutions/pages/FinancialComplianceSolutionPage'));
const EarlyCaseAssessmentSolutionPage = lazy(() => import('./pages/solutions/pages/EarlyCaseAssessmentSolutionPage'));
const SalesCallMonitoringSolutionsPage = lazy(() => import('./pages/solutions/pages/SalesCallMonitoringSolutionsPage'));
const HrLitigationSolutionsPage = lazy(() => import('./pages/solutions/pages/HrLitigationSolutionsPage'));
const SecureBackupSolutionsPage = lazy(() => import('./pages/solutions/pages/SecureBackupSolutionsPage'));
const FoiSolutionsPage = lazy(() => import('./pages/solutions/pages/FoiSolutionsPage'));

const PricingPage = lazy(() => import('./pages/pricing/PricingPage'));
const CareersPage = lazy(() => import('./pages/careers/CareersPage'));
const BlogPage = lazy(() => import('./pages/blog/BlogPage'));
const DataResidencyPage = lazy(() => import('./pages/landing-pages/security/DataResidencyPage'));
const SecurityPage = lazy(() => import('./pages/landing-pages/security/SecurityPage'));
const EmailArchivingPage = lazy(() => import('./pages/landing-pages/general/EmailArchivingPage'));
const ImArchivingPage = lazy(() => import('./pages/landing-pages/general/ImArchivingPage'));
const AvArchivingPage = lazy(() => import('./pages/landing-pages/general/AvArchivingPage'));
const ContactSalesPage = lazy(() => import('./pages/contact/ContactSalesPage'));
const ContactSupportPage = lazy(() => import('./pages/contact/ContactSupportPage'));
const ConnectorLandingPage = lazy(
	() => import('./pages/landing-pages/connectors-and-alternatives/ConnectorLandingPage')
);

const FourOhFourPage = lazy(() => import('./pages/four-o-four/FourOhFourPage'));

const history = createBrowserHistory();

if (process.env.NODE_ENV !== 'development') {
	let prevPath: string | undefined = undefined;
	history.listen((location) => {
		if (location.pathname !== prevPath) {
			prevPath = location.pathname;
			analytics.page(location.pathname, { path: location.pathname });
		}
	});
}

function App() {
	return (
		<div className="App">
			<Router history={history}>
				<Container
					element={
						<Switch>
							<Route exact path={LinkPaths.HOME}>
								{SuspendedRoute(LinkPaths.HOME, <HomePage />)}
							</Route>

							<Route exact path={LinkPaths.PRODUCTS}>
								{SuspendedRoute(LinkPaths.PRODUCTS, <ProductsHomePage />)}
							</Route>
							<Route exact path={LinkPaths.FULL_PRODUCT}>
								{SuspendedRoute(LinkPaths.FULL_PRODUCT, <FullProductPage />)}
							</Route>
							<Route exact path={LinkPaths.LITE_PRODUCT}>
								{SuspendedRoute(LinkPaths.LITE_PRODUCT, <LiteProductPage />)}
							</Route>
							<Route exact path={LinkPaths.CONNECTOR_PRODUCT}>
								{SuspendedRoute(LinkPaths.CONNECTOR_PRODUCT, <ConnectorStandAlonePage />)}
							</Route>
							<Route exact path={LinkPaths.CONNECTORS_PAGE}>
								{SuspendedRoute(LinkPaths.CONNECTORS_PAGE, <ConnectorsPage />)}
							</Route>

							<Route exact path={LinkPaths.SOLUTIONS}>
								{SuspendedRoute(LinkPaths.SOLUTIONS, <SolutionPage />)}
							</Route>
							<Route exact path={LinkPaths.SOLUTIONS_FINANCIAL_COMPLIANCE}>
								{SuspendedRoute(
									LinkPaths.SOLUTIONS_FINANCIAL_COMPLIANCE,
									<SolutionFinacialCompliancePage />
								)}
							</Route>
							<Route exact path={LinkPaths.SOLUTIONS_EARLY_CASE_ASSESSMENT}>
								{SuspendedRoute(
									LinkPaths.SOLUTIONS_EARLY_CASE_ASSESSMENT,
									<EarlyCaseAssessmentSolutionPage />
								)}
							</Route>
							<Route exact path={LinkPaths.SOLUTIONS_SALES_CALL_MONITORING}>
								{SuspendedRoute(
									LinkPaths.SOLUTIONS_SALES_CALL_MONITORING,
									<SalesCallMonitoringSolutionsPage />
								)}
							</Route>
							<Route exact path={LinkPaths.SOLUTIONS_HR_LITIGATION}>
								{SuspendedRoute(LinkPaths.SOLUTIONS_HR_LITIGATION, <HrLitigationSolutionsPage />)}
							</Route>
							<Route exact path={LinkPaths.SOLUTIONS_SECURE_BACKUP}>
								{SuspendedRoute(LinkPaths.SOLUTIONS_SECURE_BACKUP, <SecureBackupSolutionsPage />)}
							</Route>
							<Route exact path={LinkPaths.SOLUTIONS_FOI}>
								{SuspendedRoute(LinkPaths.SOLUTIONS_FOI, <FoiSolutionsPage />)}
							</Route>

							<Route exact path={LinkPaths.PRICING}>
								{ENABLE_PRICING_PAGE
									? SuspendedRoute(LinkPaths.PRICING, <PricingPage />)
									: SuspendedRoute(LinkPaths.CONTACT_SALES, <ContactSalesPage isComplete={false} />)}
							</Route>
							<Route exact path={LinkPaths.CAREERS}>
								{SuspendedRoute(LinkPaths.CAREERS, <CareersPage />)}
							</Route>
							<Route path={LinkPaths.BLOG}>
								{SuspendedRouteWithLookup(
									LinkPaths.BLOG,
									BlogPostList,
									BlogPostHomeMetadata,
									<BlogPage />
								)}
							</Route>
							<Route exact path={LinkPaths.SECURITY}>
								{SuspendedRoute(LinkPaths.SECURITY, <SecurityPage />)}
							</Route>
							<Route exact path={LinkPaths.DATA_RESIDENCY}>
								{SuspendedRoute(LinkPaths.DATA_RESIDENCY, <DataResidencyPage />)}
							</Route>
							<Route exact path={LinkPaths.EMAIL_ARCHIVING}>
								{SuspendedRoute(LinkPaths.EMAIL_ARCHIVING, <EmailArchivingPage />)}
							</Route>
							<Route exact path={LinkPaths.IM_ARCHIVING}>
								{SuspendedRoute(LinkPaths.IM_ARCHIVING, <ImArchivingPage />)}
							</Route>
							<Route exact path={LinkPaths.AV_ARCHIVING}>
								{SuspendedRoute(LinkPaths.AV_ARCHIVING, <AvArchivingPage />)}
							</Route>

							<Route exact path={LinkPaths.CONTACT_SALES}>
								{SuspendedRoute(LinkPaths.CONTACT_SALES, <ContactSalesPage isComplete={false} />)}
							</Route>
							<Route exact path={LinkPaths.CONTACT_SALES_THANKYOU}>
								{SuspendedRoute(
									LinkPaths.CONTACT_SALES_THANKYOU,
									<ContactSalesPage isComplete={true} />
								)}
							</Route>
							<Route exact path={LinkPaths.CONTACT_SUPPORT}>
								{SuspendedRoute(LinkPaths.CONTACT_SUPPORT, <ContactSupportPage isComplete={false} />)}
							</Route>
							<Route exact path={LinkPaths.CONTACT_SUPPORT_THANKYOU}>
								{SuspendedRoute(
									LinkPaths.CONTACT_SUPPORT_THANKYOU,
									<ContactSupportPage isComplete={true} />
								)}
							</Route>

							<Route path={LegalLinkPaths.TERMS_RAW}>
								<TermsPages />
							</Route>

							{ConnectorList.map((x) => (
								<Route exact path={x.link} key={x.link}>
									{SuspendedRouteWithLookup(
										x.link,
										ConnectorList,
										undefined,
										<ConnectorLandingPage page={x.type} />
									)}
								</Route>
							))}

							{/* {AlternativeList.map(x =>
								(<Route exact path={x.link} key={x.link}>
									{SuspendedRoute(<AlternativesPage page={x.type} />)}
								</Route>)
							)} */}

							<Route>{SuspendedRoute('404', <FourOhFourPage />)}</Route>
						</Switch>
					}
				/>
				{cm()}
			</Router>
		</div>
	);
}

export default App;
