import React, { Suspense } from 'react';
import HelmetWrapper from './../metadata/HelmetWrapper';
import { Loading } from './../pages/shared/Loading';
import { MetadataLookup } from './../metadata/Metadata';

export const SuspendedRoute = (canonicalLink: string, a: JSX.Element) => (
	<HelmetWrapper canonicalLink={canonicalLink}>
		<Suspense fallback={<Loading />}>{a}</Suspense>
	</HelmetWrapper>
);
export const SuspendedRouteWithLookup = (
	canonicalLink: string,
	lookup: MetadataLookup[],
	lookupDefault: MetadataLookup | undefined,
	a: JSX.Element
) => (
	<HelmetWrapper canonicalLink={canonicalLink} lookup={lookup} lookupDefault={lookupDefault}>
		<Suspense fallback={<Loading />}>{a}</Suspense>
	</HelmetWrapper>
);
